import React, { useEffect, useState } from 'react';
import * as Api from 'api';
import useAuth from 'redux/auth/auth.hook';
import { Row, Col, Grid, Card } from 'antd';
import GaugeChart from 'react-gauge-chart';
import { Doughnut } from 'react-chartjs-2';
import { Link, useHistory } from 'react-router-dom';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import classes from './style.module.scss';

am4core.useTheme(am4themes_animated);
const { useBreakpoint } = Grid;
const pieOptions = {
  plugins: {
    legend: {
      position: 'right',
    },
  },
};

const DashboardDataUsage = (props) => {
  const { md } = useBreakpoint();
  const { auth: { profile } } = useAuth();
  const [dataPieChart, setDataPieChart] = useState<any>();
  const [totalDataAmount, setTotalDataAmount] = useState<any>(100);
  const [usedDataAmount, setUsedDataAmount] = useState<any>(0);

  useEffect(() => {
    if (profile) {
      Api.DATA_USAGE_CURRENT().then((res: any) => {
        const usage = JSON.parse(res.text);
        const dataLimit = usage.usage_limitation;
        const usedData = usage.current_usage;
        setTotalDataAmount(dataLimit);
        setUsedDataAmount(usedData);

        Api.DATA_USAGE_TOP_CAMERAS().then((res1 : any) => {
          const cameras = JSON.parse(res1.text);
          const labels = cameras.map((item) => item.name);
          labels.push('Remain');
          const values = cameras.map((item) => item.data_usage);
          if (dataLimit > 0) {
            values.push(dataLimit - usedData);
          } else {
            values.push(10 * usedData);
          }

          const dataChart = {
            labels,
            datasets: [
              {
                label: 'Camera\'s Usage',
                data: values,
                backgroundColor: ['rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 255, 255, 1)'],
                borderColor: ['rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)'],
                borderWidth: 1,
              },
            ],
          };
          setDataPieChart(dataChart);
        });
      });
    }
  }, [profile]);

  const checkDataUnit = (size: number) => {
    if (size == -1) {
      return 'Unlimited';
    }
    let dataString = '0B';
    if (size > 1000000000) {
      const value = (size / 1000000000).toFixed(1);
      dataString = value.concat('GB');
    } else if (size > 1000000) {
      const value = (size / 1000000).toFixed(1);
      dataString = value.concat('MB');
    } else if (size > 1000) {
      const value = (size / 1000).toFixed(1);
      dataString = value.concat('KB');
    } else if (size > 1) {
      const value = size.toFixed(1);
      dataString = value.concat('B');
    }
    return dataString;
  };

  return (
    <Row align="middle">
      <Col md={12} xs={24} style={{ padding: 8 }}>
        <div style={{ marginLeft: 20 }}>
          <p>Data Limit: {checkDataUnit(totalDataAmount)}</p>
          <p>Used Data: {checkDataUnit(usedDataAmount)}</p>
        </div>
        <GaugeChart id="gauge-data-usage" nrOfLevels={20} percent={totalDataAmount > 0 ? usedDataAmount / totalDataAmount : 0} textColor="black" />
      </Col>
      <Col md={12} xs={24} style={{ padding: 8 }}>
        {/* <div style={{ marginLeft: 20 }}>
          <p>Camera&apos;s Usage</p>
        </div> */}
        <Doughnut data={dataPieChart} options={pieOptions} />
      </Col>
      {props.showLink &&
        <Link id={classes.link_data_usage} to="/datausage">View Usage Details <i className="fa fa-arrow-circle-right" /></Link>}
    </Row>
  );
};

export default DashboardDataUsage;
