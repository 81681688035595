/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.min.css';
import { Card } from 'antd';

import { Line } from 'react-chartjs-2';
import { colorHog, colorHog_alpha } from 'res';
import classes from './style.module.scss';

interface props {
  record: any;
}

function dayOfWeekAsString(arg: string) {
  // eslint-disable-next-line no-var
  // eslint-disable-next-line radix
  const argValue = parseInt(arg) - 1;
  return ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'][argValue];
}

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
];

function getMonthName(arg: string) {
  // eslint-disable-next-line radix
  const argValue = parseInt(arg) - 1;
  if (argValue < 0 || argValue == null) return '';
  return monthNames[argValue];
}

const CHART_LABELS = ['12AM', '3AM', '6AM', '9AM', '12PM', '3PM', '6PM', '9PM'];

const options = {
  lineTension: 0.3,
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        display: false,
        // beginAtZero: true,
      },
    },
    // yAxes: [
    //   {
    //     ticks: {
    //       display: false,
    //       beginAtZero: true,
    //     },
    //   },
    // ],
  },
};

// eslint-disable-next-line no-empty-pattern
const ForecastPredictionChart: React.FC<props> = ({ record }) => {
  const [chartPredict, setChartPredict] = useState<any>([]);
  const [chartLabel, setChartLabel] = useState<string>(CHART_LABELS[0]);
  useEffect(() => {
    // console.log('record', record);
    const arrayPredict: number[] = [];
    let max = 0;
    let maxIndex = 0;
    for (let i = 0; i < record.data_hourly.length; i++) {
      const item = record.data_hourly[i];
      arrayPredict.push(item);
      if (item > max) {
        max = item;
        maxIndex = i;
      }
    }
    if (maxIndex < CHART_LABELS.length) {
      setChartLabel(CHART_LABELS[maxIndex]);
    }
    setChartPredict(arrayPredict);
  }, [record]);

  const dataChart = {
    labels: CHART_LABELS,
    datasets: [
      {
        label: chartLabel,
        data: chartPredict,
        fill: true,
        backgroundColor: colorHog_alpha,
        borderColor: colorHog,
        borderWidth: 2,
      },
    ],
  };

  return (
    <Card>
      <div className={classes.divHeader}>
        <div className={classes.divDay}>
          <h5 className={classes.firstWeekday}>{dayOfWeekAsString(record.weekday)}</h5>
          <h5>{getMonthName(record.month)}/{record.day}</h5>
        </div>
        <div>
          {/* <img src={`/icons_moon_phase/${record.astro.moon_phase}.png`} alt="" className={classes.img_moon} />
          <h5>{record.astro.moon_phase}</h5> */}
          {/* <h5 className={classes.sunrise_help}>Sunrise/set</h5>
            <h5>{record.astro.sunrise}/{record.astro.sunset}</h5> */}
        </div>
      </div>
      <Card bodyStyle={{ padding: '8px' }}>
        <Line data={dataChart} options={options} />
      </Card>
    </Card>
  );
};

export default ForecastPredictionChart;
