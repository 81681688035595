import React, { useEffect, useState } from 'react';

function CameraVersion(props) {
  console.log('image', props);
  const { detail } = props;
  if (detail.version == 'A') {
    return <img style={{ height: props.size }} alt="test" src="/icons_camera/Standard-150W.png" />;
  }
  if (detail.version == 'B') {
    return <img style={{ height: props.size }} alt="test" src="/icons_camera/Mini-150W.png" />;
  }
  return <img style={{ height: props.size }} alt="test" src="/icons_camera/smartcam_datacam.jpg" />;
}
export default CameraVersion;
