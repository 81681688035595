import { DollarOutlined, FieldTimeOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Checkbox, ThemeProvider } from '@mui/material';
import { Button, Input, InputNumber, notification, Popconfirm, Spin, Table } from 'antd';
import * as Api from 'api';
import { AccountType } from 'components/profileAccount';
import ProfileSubscriptionCard from 'components/profileSubscriptionCard';
import React, { useEffect, useState } from 'react';
import useAuth from 'redux/auth/auth.hook';
import { PATHS } from 'res/routes';
import outerTheme from 'theme/mui';
import classes from './style.module.scss';

const ProfileNotification = () => {
  const [countActiveSmart, setCountActiveSmart] = useState(0);
  const { auth: { profile } } = useAuth();
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState<AccountType>({
    id: 0,
    name: '',
    email: '',
    password: '',
    firstname: '',
    lastname: '',
    country: 'US',
    state: '',
    city: '',
    zipcode: '',
    phonenumber: '',
    customer_id: '',
  });
  const [smartcams, setSmartcams] = useState<any[]>([]);
  const [subscribe_quantity, setSubscribeQuantity] = useState(0);

  useEffect(() => {
    let count = 0;
    for (let index = 0; index < smartcams.length; index++) {
      const element = smartcams[index];
      if (element.is_active == 1) {
        count++;
      }
    }
    setCountActiveSmart(count);
  }, [smartcams]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'IMEI',
      dataIndex: 'IMEI',
      key: 'IMEI',
    },
    {
      title: 'Email',
      dataIndex: 'delete',
      render: (_: any, record: any) => (
        <ThemeProvider theme={outerTheme}>
          <Checkbox checked={record.notification_email == 1} disabled={record.is_active == 0} onChange={(e) => handleChangeEmail(e, record.key)} />
        </ThemeProvider>
      ),
    },
    {
      title: 'Push',
      dataIndex: 'delete',
      render: (_: any, record: any) => (
        <ThemeProvider theme={outerTheme}>
          <Checkbox checked={record.notification_push == 1} disabled={record.is_active == 0} onChange={(e) => handleChangePush(e, record.key)} />
        </ThemeProvider>
      ),
    },
    {
      title: 'Activate',
      dataIndex: 'delete',
      render: (_: any, record: any) => (
        record.is_active == 1 ? (
          <Popconfirm title="Sure to Suspend to the SD Card Camera?" onConfirm={() => onChangeActivate(record.IMEI, 0)}>
            <ThemeProvider theme={outerTheme}>
              <Checkbox checked={record.is_active == 1} />
            </ThemeProvider>
            <a> </a>
          </Popconfirm>
        ) : (
          <Popconfirm title="Sure to Reactivate?" onConfirm={() => onChangeActivate(record.IMEI, 1)}>
            <ThemeProvider theme={outerTheme}>
              <Checkbox checked={record.is_active == 1} />
            </ThemeProvider>
            <a> </a>
          </Popconfirm>
        )),
    },
  ];

  useEffect(() => {
    if (profile) {
      httpLoadAccount();
    }
  }, [profile]);

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>, key: any) => {
    const enable = event.target.checked ? 1 : 0;
    const params = {
      camera_id: key,
      enable,
    };

    Api.CAMERA_NOTIFICATION_EMAIL(params).then((res) => {
      httpLoadAccount();
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const handleChangePush = (event: React.ChangeEvent<HTMLInputElement>, key: any) => {
    const enable = event.target.checked ? 1 : 0;
    const params = {
      camera_id: key,
      enable,
    };

    Api.CAMERA_NOTIFICATION_PUSH(params).then((res) => {
      httpLoadAccount();
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onChangeActivate = (IMEI, is_active) => {
    const params = {
      IMEI,
      is_active,
    };
    setLoading(true);
    Api.CAMERA_ACTIVATE(params).then(((res: any) => {
      setLoading(false);
      const data = JSON.parse(res.text);
      if (data.result == 'OK') {
        httpLoadAccount();
      } else {
        notification.open({
          message: res.text,
        });
      }
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const httpLoadAccount = () => {
    Api.PROFILE_WITH_SMARTCAMS().then(((res: any) => {
      console.log(res.text);
      const data = JSON.parse(res.text);
      setAccount(data.user);
      setSubscribeQuantity(data.user.smart_limit > 0 ? data.user.subscribe_quantity : 0);
      const result_smartcams = data.smartcams.map((row: { id: any; name: any; IMEI: any; is_active: any; notification_email: any, notification_push }) => ({
        key: row.id, // I added this line
        id: row.id,
        name: row.name,
        is_active: row.is_active,
        IMEI: row.IMEI,
        notification_email: row.notification_email,
        notification_push: row.notification_push,
      }));
      setSmartcams(result_smartcams);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  return (
    <div>
      <p>Suspending your smart, cam or mini cam, will allow you to continue to use your camera as a normal SD card based camera. You will be able to view your images and manually upload new images as well as access all of your activity, charts and predictions. However, the cellular service to your camera will be turned off , and you will need to go to Account Management and update the number of cameras to reflect your changes</p>
      <Spin spinning={loading} size="large">
        <div className={classes.profileItem}>
          <strong><VideoCameraOutlined />&nbsp;You have {countActiveSmart} active smart cams now.</strong>
          <Table
            rowClassName={(record: any, index) => (record.is_active == 0 ? classes.rowSuspended : classes.normal)}
            dataSource={smartcams}
            columns={columns}
            scroll={{ x: 400, y: 500 }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default ProfileNotification;
