import { Card, Col, Row, Space } from 'antd';
import React, { useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { colorDeer, colorDeer_alpha, colorHog, colorHog_alpha } from 'res';
import classes from './style.module.scss';

const CHART_LABELS = ['12AM', '3AM', '6AM', '9AM', '12PM', '3PM', '6PM', '9PM'];

const options = {
  lineTension: 0.3,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

interface props {
  data: Array<number>
  label: string
  arraySunImages: Array<string>
}

const WeatherChart: React.FC<props> = (props) => {
  const dataChart = {
    labels: CHART_LABELS,
    datasets: [
      {
        label: props.label,
        data: props.data,
        fill: true,
        backgroundColor: colorHog_alpha,
        borderColor: colorHog,
        borderWidth: 2,
      },
    ],
  };

  useEffect(() => {
    console.log('besttime');
  }, []);

  return (
    <Card bodyStyle={{ padding: '8px' }}>
      <Line data={dataChart} options={options} />
      <div className={classes.imageContainer}>
        {props.arraySunImages.map((element: string, index: number) => (
          index == 0 ? <img key={element} src={element} alt="" className={classes.img_sun_first} /> : <img key={element} src={element} alt="" className={classes.img_sun} />
        ))}
      </div>
    </Card>
  );
};

export default WeatherChart;
