import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Grid, Modal, Button, Input, notification, Spin, Alert } from 'antd';
import CameraCard from 'components/cameraCard';
import LeftCameraMap from 'components/cameraMap';

import * as Api from 'api';
import useAuth from 'redux/auth/auth.hook';
import { CameraPinDetailType, CameraPinType } from 'helpers/types';

import { PlusOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import useApi from 'redux/api/api.hook';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

const CameraPage = () => {
  const { md } = useBreakpoint();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { cameraMapBound } = useApi();

  const { auth: { profile } } = useAuth();
  const [cameras, setCameras] = useState<CameraPinDetailType[]>([]);
  const [cameraName, setCameraName] = useState('');
  const [cameraIMEI, setCameraIMEI] = useState('');
  const [loading, setLoading] = useState(false);
  const leftMap = useRef<any>();
  const [low_battey_alert, setLowBatteryAlert] = useState('');

  useEffect(() => {
    if (profile) {
      loadData();
    }
  }, [profile]);

  const loadData = () => {
    setLoading(true);
    Api.MAP_CAMERAS_PINS_DETAIL().then(((res: any) => {
      const result = JSON.parse(res.text);
      setCameras(result.cameras);

      let camera_string = '';
      let camera_more_one = 0;
      result.cameras.forEach((camera) => {
        if (camera.battery1 != '' && parseFloat(camera.battery1) < 8.6 && parseFloat(camera.battery1) > 7) {
          if (camera_string != '') {
            camera_string += ', ';
            camera_more_one = 1;
          }
          camera_string += camera.name;
        }
        console.log('interval voltage', parseFloat(camera.battery1));
      });
      console.log('done');
      if (camera_string != '') {
        if (camera_more_one == 1) {
          setLowBatteryAlert(`The internal battery voltages of ${camera_string} are getting low, we recommend checking the batteries soon.`);
        } else {
          setLowBatteryAlert(`The internal battery voltage of ${camera_string} is getting low, we recommend checking the batteries soon.`);
        }
      }

      setLoading(false);
      if (result.cameras.length == 0) {
        setIsModalVisible(true);
      }
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const clickAddCamera = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (cameraIMEI == '') {
      notification.open({
        message: 'Please input IMEI of your camera',
      });
      return;
    }
    Api.CAMERA_CREATE_SMART({
      user_id: profile.user_id,
      IMEI: cameraIMEI,
      camera_name: cameraName,
      latitude: cameraMapBound.center.lat,
      longitude: cameraMapBound.center.lng,
    }).then(((res: any) => {
      const data = JSON.parse(res.text);
      if (data.result == 'ok' || data.result == 'OK') {
        setIsModalVisible(false);
        loadData();
      } else {
        notification.open({
          message: data.message,
        });
      }
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChange = (e) => {
    console.log(e.target.name);
    if (e.target.name == 'name') {
      setCameraName(e.target.value);
    } else if (e.target.name == 'IMEI') {
      setCameraIMEI(e.target.value);
    }
  };

  const handleMapBoundChange = () => {
    if (leftMap.current) {
      leftMap.current.handleMapBoundChange();
    }
  };

  return (
    <>
      <Row>
        <Col md={12} xs={24} className={classes.leftMap}>
          <LeftCameraMap cameras={cameras} ref={leftMap} />
        </Col>
        <Col md={12} xs={24}>
          {low_battey_alert != '' && (
            <Alert
              message="Action Required."
              description={low_battey_alert}
              type="warning"
              closable
            />
          )}
          <div className={classes.content} style={{ padding: md ? '12px 12px 12px 0px' : 8 }}>
            <Col xs={24} md={24}>
              <Spin spinning={loading} size="large">
                <Row>
                  {cameras.map((e) => (
                    <Col key={e.id} xs={24} md={24}>
                      <CameraCard detail={e} />
                    </Col>
                  ))}
                </Row>
              </Spin>
            </Col>
          </div>
        </Col>
        <div className={classes.containerFloat}>
          <Link to="/cameraAddPin">
            <Button type="primary" shape="round" size="large" icon={<PlusOutlined />} onClick={handleMapBoundChange}>
              Marker
            </Button>
          </Link>
          <Button type="primary" shape="round" size="large" icon={<PlusOutlined />} onClick={clickAddCamera}>
            Camera
          </Button>
        </div>
      </Row>
      <Modal
        title="Add Smart Camera"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Link key={1} to="/cameraAdd">
            <Button
              key="All Other Cameras"
              onClick={handleMapBoundChange}
            >
              All Other Cameras
            </Button>
          </Link>,
          <Button key="Add Smart Cam" type="primary" onClick={handleOk}>
            Add Smart Cam
          </Button>,
        ]}
      >
        <p>If you have a WiseEye Smart Cam, please enter the IMEI below and click the &quot;Add Smart Cam&quot; button on the right. If you are not adding a WiseEye SmartCam, please click the &quot;All Other Cameras&quot; button below.</p>
        <Input name="IMEI" placeholder="IMEI" onChange={handleChange} />
        <Input name="name" placeholder="Camera's name(optional)" onChange={handleChange} />
      </Modal>
    </>
  );
};

export default CameraPage;
