import { Button, Col, DatePicker, Divider, Form, notification, Popconfirm, Radio, Row, Select, Tooltip } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import * as Api from 'api';
import React, { useEffect, useState } from 'react';
import ImageEditTag from 'components/ImageEditTag';
import ImageFilterTag from 'components/ImageFilterTag';
import useApi from 'redux/api/api.hook';
import useAuth from 'redux/auth/auth.hook';
import { Checkbox, FormControlLabel, FormGroup, ThemeProvider } from '@mui/material';
import { PlayCircleOutlined, StarOutlined } from '@ant-design/icons';
import outerTheme from 'theme/mui';
import classes from './style.module.scss';

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

const FilterImage = (props) => {
  const [trachCounter, setTrashCounter] = useState(0);
  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const { auth: { profile } } = useAuth();
  const { images, filterOptions, tags, setImages, setFilterOption, setTags, setTagAssigns } = useApi();
  const { md } = useBreakpoint();

  useEffect(() => {
    if (profile) {
      http_load_tags();
    }
  }, [profile]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (trachCounter > 0) {
        setTrashCounter(trachCounter - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [trachCounter]);

  const editCategories = [
    'Deer (Antlered)',
    'Deer (Non Antlered)',
    'Hog',
    'Turkey',
    'People',
    'Vehicle',
    'Predator',
    'Other',
  ];

  const onValuesChange = (values, allvalues) => {
    setFilterOption({ ...allvalues, user_id: profile.user_id });
  };

  const onSelectTag = (value) => {
    setFilterOption({ ...filterOptions, tag: value });
  };

  const onChangeDate = (date: any, dateString: string[]) => {
    setFilterOption({ ...filterOptions, fromDate: dateString[0], toDate: dateString[1] });
  };

  const onChangeCheckBox = (e) => {
    // checkAllCameraCards(e.target.checked);
    images.map((image) => {
      image.selected = e.target.checked;
      return null;
    });
    setImages(images);
  };

  // http edit
  const onChangeCamera = (e) => {
    const ids: any = [];
    images.map((image) => {
      if (image.selected) {
        ids.push(image.id);
      }
      return null;
    });

    formEdit.resetFields();
    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
      return;
    }

    httpEdit(ids, 'camera_id', e, () => {
      let shouldMove = true;
      if (filterOptions.camera_id == '0' || filterOptions.camera_id == undefined) {
        shouldMove = false;
      }
      let newImages = [...images];
      if (shouldMove) {
        newImages = newImages.filter((item) => !item.selected);
      } else {
        const cam = props.cameraArr.find((item) => item.id == e);
        newImages.map((item) => {
          if (item.selected) {
            item.camera_id = cam.id;
            item.camera_name = cam.name;
            item.selected = false;
          }
          return item;
        });
      }
      setImages(newImages);
    });
  };

  const onChangeCategory = (e) => {
    const ids: any = [];

    images.map((image) => {
      if (image.selected) {
        ids.push(image.id);
      }
      return null;
    });

    formEdit.resetFields();
    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
      return;
    }

    httpEdit(ids, 'category', e, () => {
      let shouldMove = false;
      if (filterOptions.category == 'all' || filterOptions.category == undefined) {
        shouldMove = false;
      } else if ((filterOptions.category == 'deer' && filterOptions.antlered == 0)) {
        if (e.includes('Deer')) {
          shouldMove = false;
        } else {
          shouldMove = true;
        }
      } else {
        shouldMove = true;
      }
      let newImages = [...images];
      if (shouldMove) {
        newImages = newImages.filter((item) => !item.selected);
      } else if (e.includes('Deer')) {
        const arr = e.replace(/[)(]/g, '').split(' ');
        const cate = arr[0];
        const cate_sub = arr[1];
        newImages.map((item) => {
          if (item.selected) {
            item.category = cate;
            item.category_sub = cate_sub;
            item.selected = false;
          }
          return item;
        });
      } else {
        newImages.map((item) => {
          if (item.selected) {
            item.category = e;
            item.selected = false;
          }
          return item;
        });
      }
      setImages(newImages);
      notification.open({
        message: 'Your Change was Successful',
      });
    });
  };
  const onChangeTag = (tag, category, type) => {
    console.log(tag, category, type);
    const ids: any = [];

    images.map((image) => {
      if (image.selected) {
        ids.push(image.id);
      }
      return null;
    });

    formEdit.resetFields();
    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
      return;
    }

    if (type == 2 && category != 'None') {
      const params = {
        user_id: profile.user_id,
        category,
        tag,
      };

      Api.IMAGE_TAG_ASSIGN_ADD(params).then((() => {
        console.log('tag assign add OK');
        http_load_tags();
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
    }

    httpEdit(ids, 'tag', tag, () => {
      let shouldMove = true;
      if (filterOptions.tag == '0' || filterOptions.tag == undefined) {
        shouldMove = false;
      }
      let newImages = [...images];
      if (shouldMove) {
        newImages = newImages.filter((item) => !item.selected);
      } else {
        newImages.map((item) => {
          if (item.selected) {
            item.tag = tag;
            if (category == 'None') {
              item.selected = false;
            }
          }
          return item;
        });
      }
      setImages(newImages);
      if (type == 2) {
        http_load_tags();
      }

      if (category != 'None') {
        onChangeCategory(category);
      } else {
        notification.open({
          message: 'Your Change was Successful',
        });
      }
    });
  };
  const httpTrash = () => {
    setTrashCounter(0);
    const ids: any = [];

    images.map((e) => {
      if (e.selected) {
        ids.push(e.id);
      }
      return null;
    });

    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
    } else {
      const params = {
        ids,
      };

      Api.IMAGE_TRASH(params).then((() => {
        props.reloadPage();
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
    }
  };

  const httpEdit = (ids, field, value, callback) => {
    const params = {
      ids,
      field,
      value,
    };

    Api.IMAGE_EDIT(params).then((() => {
      callback();
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const http_load_tags = () => {
    Api.IMAGE_TAG_ASSIGN_ALL().then(((res: any) => {
      const jsonObject = JSON.parse(res.text);
      setTags(jsonObject.tags);
      setTagAssigns(jsonObject.assigns);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const changeDeerSubCategroy = (e) => {
    const val = e.target.value;
    setFilterOption({ ...filterOptions, antlered: val });
  };

  const onChangeVideoOnly = (e) => {
    const val = e.target.value;
    setFilterOption({ ...filterOptions, video_only: !filterOptions.video_only });
  };

  const onChangeFavoriteOnly = (e) => {
    const val = e.target.value;
    setFilterOption({ ...filterOptions, favorite_only: !filterOptions.favorite_only });
  };

  const onTrash = () => {
    const ids: any = [];

    images.map((e) => {
      if (e.selected) {
        ids.push(e.id);
      }
      return null;
    });

    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
    } else {
      setTrashCounter(3);
    }
  };

  const onChangeTakenTime = (date, dateString) => {
    console.log(date, dateString);
    const ids: any = [];
    images.map((image) => {
      if (image.selected) {
        ids.push(image.id);
      }
      return null;
    });

    formEdit.resetFields();
    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
      return;
    }

    httpEdit(ids, 'time', dateString, () => {
      const newImages = [...images];
      newImages.map((item) => {
        if (item.selected) {
          if (item.manually_uploaded == '1') {
            item.time = dateString;
          }
          item.selected = false;
        }
        return item;
      });
      setImages(newImages);
    });
  };

  return (
    <div className={classes.wrapper}>
      <ThemeProvider theme={outerTheme}>
        <Form form={form} style={{ marginTop: md ? 20 : 0 }} onValuesChange={onValuesChange}>
          <Form.Item label="Sort" name="sort" style={{ marginBottom: 8 }} className={classes.formInputWrapper} initialValue="DESC">
            <Select dropdownStyle={{ position: 'fixed' }}>
              <Option key="DESC" value="DESC">Newest to Oldest</Option>
              <Option key="ASC" value="ASC">Oldest to Newest</Option>
            </Select>
          </Form.Item>
          <FormControlLabel control={<Checkbox defaultChecked />} label="Select All" />
          <Divider style={{ marginBottom: 8 }}>Filter</Divider>
          <Button type={filterOptions.video_only ? 'primary' : 'default'} className={classes.editTrash} onClick={onChangeVideoOnly} icon={<PlayCircleOutlined />}>
            Videos Only
          </Button>
          <Button type={filterOptions.favorite_only ? 'primary' : 'default'} className={classes.editTrash} onClick={onChangeFavoriteOnly} icon={<StarOutlined />}>
            Favorites Only
          </Button>
          {(props.imageFilter == 'deer' || props.imageFilter == 'Deer') && (
            <Radio.Group value={filterOptions.antlered} className={classes.categoryGroup} onChange={changeDeerSubCategroy}>
              <Row>
                <Col span={12}><Radio.Button className={classes.categoryBtn} value="0">All Deer</Radio.Button></Col>
                <Col span={12}><Radio.Button className={classes.categoryBtn} value="1">Bucks Only</Radio.Button></Col>
              </Row>
            </Radio.Group>
          )}
          <Form.Item name="camera_id" className={classes.formInputWrapper} initialValue="0">
            <Select dropdownStyle={{ position: 'fixed' }}>
              <Option key={0} value="0">All Cameras</Option>
              <OptGroup label="Cameras">
                {props.cameraArr.map((val: any) => (
                  <Option key={val.id} value={val.id}>{val.name}</Option>
                ))}
              </OptGroup>
              <OptGroup label="Camera Groups">
                {props.cameraGroups.map((val: any) => (
                  <Option key={val.id} value={`group${val.id}`}>{val.name}</Option>
                ))}
              </OptGroup>
            </Select>
          </Form.Item>
          <Form.Item name="tag" className={classes.formInputWrapper} initialValue="">
            <ImageFilterTag
              size="large"
              onSelectTag={onSelectTag}
              dropdownStyle={{ position: 'fixed' }}
            />
          </Form.Item>
          <Form.Item className={classes.formInputWrapper}>
            <div>
              <RangePicker style={{ width: '100%' }} onChange={onChangeDate} />
            </div>
          </Form.Item>
        </Form>

        <Divider style={{ marginBottom: 8 }}>Edit</Divider>
        <Form form={formEdit}>
          <div>
            <Form.Item name="to_camera" className={classes.formInputWrapper}>
              <Select placeholder="Change Camera To:" onChange={onChangeCamera} dropdownStyle={{ position: 'fixed' }}>
                {props.cameraArr.map((val: any) => (
                  <Option key={val.id} value={val.id}>{val.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item name="to_category" className={classes.formInputWrapper}>
              <Select placeholder="Change Category To:" onChange={onChangeCategory} dropdownStyle={{ position: 'fixed' }}>
                {editCategories.map((val, i) => (
                  <Option key={i} value={val}>{val}</Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div style={{ marginTop: 16 }}>
            <Form.Item name="to_tag" className={classes.formInputWrapper}>
              <ImageEditTag tags={tags} onChangeTag={onChangeTag} />
            </Form.Item>
          </div>
          <div style={{ marginTop: 16 }}>
            <Tooltip title="Only manually uploaded images will be changed">
              <Form.Item name="edit_date" className={classes.formInputWrapper}>
                <DatePicker
                  style={{
                    width: '100%',
                  }}
                  placeholder="Change Taken Time To:"
                  showTime
                  onChange={onChangeTakenTime}
                />
              </Form.Item>
            </Tooltip>
          </div>
          {trachCounter > 0 && (
            <Button type="primary" onClick={httpTrash} className={classes.editTrash} danger>
              Confirm ({trachCounter})
            </Button>
          )}
          {trachCounter == 0 && (
            <Button type="primary" onClick={onTrash} className={classes.editTrash}>
              Trash
            </Button>
          )}
        </Form>
      </ThemeProvider>
    </div>
  );
};

export default FilterImage;
