import { Card, Col, Grid, Input, Modal, Popconfirm, Row, Select, Spin, Table, notification } from 'antd';
import * as Api from 'api';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import useAuth from 'redux/auth/auth.hook';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;
const { Option, OptGroup } = Select;

const editCategories = [
  'Deer (Antlered)',
  'Deer (Non Antlered)',
  'Hog',
  'Turkey',
  'People',
  'Vehicle',
  'Predator',
  'Other',
];

const ImageTagPage = () => {
  const { md } = useBreakpoint();
  const { auth: { profile } } = useAuth();
  const [tags, setTags] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalInput, setModalInput] = useState('');
  const [originalTag, setOriginalTag] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (profile) {
      reloadGroupData();
    }
  }, [profile]);

  const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    // setItems([...items, name || `New item ${index++}`]);
    // setName('');
    // setTimeout(() => {
    //   inputRef.current?.focus();
    // }, 0);
  };

  const reloadGroupData = () => {
    setLoading(true);
    Api.TAG_DETAIL_ALL().then(((res: any) => {
      setLoading(false);
      const obj = JSON.parse(res.text);
      const result: any = [];
      obj.forEach((element, index) => {
        // if (element.category == '') {
        //   element.category = null;
        // }
        const t = new Date(Number(element.last_uploaded_at) * 1000);
        element.uploaded_at = moment(t).format('D MMM YYYY');
        result.push(element);
      });
      setTags(result);
    })).catch((error: any) => {
      console.log('error ===>', error);
      setLoading(false);
    });
  };

  const handleDelete = (key: any) => {
    const params = {
      tag: key,
      test: 'test',
    };
    Api.TAG_DELETE(params).then((res) => {
      reloadGroupData();
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const handleClear = (key: any) => {
    const params = {
      tag: key,
      test: 'test',
    };
    Api.TAG_CLEAR(params).then((res) => {
      reloadGroupData();
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const updateAssign = (tag, category) => {
    const params = {
      tag,
      category,
    };
    Api.TAG_ASSIGN_UPDATE(params).then((res) => {
      reloadGroupData();
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onEdit = (tag) => {
    console.log(tag);
    setModalInput(tag);
    setOriginalTag(tag);
    setIsModalOpen(true);
  };

  const onEditOK = () => {
    console.log(modalInput);
    if (modalInput == '') {
      notification.open({
        message: 'Please input tag',
      });
      return;
    }
    for (const record of tags) {
      if (record.tag === modalInput) {
        notification.open({
          message: 'Same Tag Exist',
        });
        return;
      }
    }
    setIsModalOpen(false);
    const params = {
      tag: originalTag,
      new_tag: modalInput,
    };
    Api.TAG_UPDATE(params).then((res) => {
      reloadGroupData();
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const columns = [
    {
      title: 'Tag',
      dataIndex: 'tag',
      key: 'tag',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      minWidth: 200,
      render: (_: any, record: any) => (
        tags.length >= 1 ? (
          <Select
            defaultValue={record.category}
            size="large"
            placeholder="Assign To:"
            onChange={(e) => updateAssign(record.tag, e)}
            dropdownStyle={{ position: 'fixed' }}
            style={{ width: '100%' }}
          >
            {editCategories.map((item, i) => (
              <Option key={i} value={item}>{item}</Option>
            ))}
            <Option key={-1} value="" className={classes.optionClear}>-NOT ASSIGN-</Option>
          </Select>
        ) : null),
    },
    {
      title: 'Last Uploaded At',
      dataIndex: 'uploaded_at',
      key: 'uploaded_at',
    },
    {
      title: 'Edit Tag',
      dataIndex: 'operation',
      render: (_: any, record: { tag: any; }) => (
        <a onClick={() => onEdit(record.tag)}>
          Edit
        </a>
      ),
    },
    {
      title: 'Delete Images',
      dataIndex: 'operation',
      render: (_: any, record: { tag: any; }) => (
        tags.length >= 1 ? (
          <Popconfirm title="Sure to delete this tag's images?" onConfirm={() => handleDelete(record.tag)}>
            <a>Delete</a>
          </Popconfirm>
        ) : null),
    },
    {
      title: 'Clear Tag',
      dataIndex: 'operation',
      render: (_: any, record: { tag: any; }) => (
        tags.length >= 1 ? (
          <Popconfirm title="Sure to clear this tag from images?" onConfirm={() => handleClear(record.tag)}>
            <a>Clear</a>
          </Popconfirm>
        ) : null),
    },
  ];

  return (
    <div className={classes.content}>
      <Row>
        <Col md={24} xs={24}>
          <Card title="My All Tags" style={{ margin: '2px' }}>
            <Spin spinning={loading} size="large">
              <Table dataSource={tags} columns={columns} />
            </Spin>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Tag Update"
        visible={isModalOpen}
        onOk={onEditOK}
        onCancel={() => setIsModalOpen(false)}
      >
        <Input
          value={modalInput}
          placeholder="Please Input New Tag"
          onChange={(e) => setModalInput(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default ImageTagPage;
