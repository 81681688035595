import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { Card, Col, Drawer, Grid, Row, Spin } from 'antd';
import * as Api from 'api';
import ChartBesttime from 'components/chartBesttime';
import ChartBesttimeLine from 'components/chartBesttimeLine';
import ChartCamera from 'components/chartCamera';
import ChartSummaryBar from 'components/chartSummaryBar';
import SubActivityFilter from 'components/SubActivityFilter';
import React, { useEffect, useState } from 'react';
import useApi from 'redux/api/api.hook';
import useAuth from 'redux/auth/auth.hook';
import * as Res from 'res';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

const LABELS_TEMP = ['0F', '10F', '20F', '30F', '40F', '50F', '60F', '70F', '80F', '90F', '100F+'];
const LABELS_WIND_SPEED = ['0MPH', '3MPH', '6MPH', '9MPH', '12MPH', '15MPH', '18MPH', '21MPH+'];
const LABELS_WIND_DIR = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
const LABELS_PRESSURE = ['1010MB', '1015MB', '1020MB', '1025MB', '1030MB', '1035MB', '1040MB', '1045MB', '1050MB', '1055MB', '1060MB'];
const LABELS_MOON = ['New Moon', 'Wax. Crescent', '1st Quarter', 'Wax. Gibbous', 'Full Moon', 'Wan. Gibbous', '2nd Quarter', 'Wan. Crescent'];

const SubActivityPage = () => {
  const { showLeftFilter, setShowLeftFilter } = useApi();
  const { md, xs } = useBreakpoint();
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [loadingWeather, setLoadingWeather] = useState(false);
  const [loadingDate, setLoadingDate] = useState(false);

  const { auth: { profile } } = useAuth();

  const [weatherMoon, setWeatherMoon] = useState<any[]>([]);
  const [weatherPressure, setWeatherPressure] = useState<any[]>([]);
  const [weatherTemp, setWeatherTemp] = useState<any[]>([]);
  const [weatherWindDir, setWeatherWindDir] = useState<any[]>([]);
  const [weatherWindSpeed, setWeatherWindSpeed] = useState<any[]>([]);

  const [dateDailyKey, setDateDailyKey] = useState<any[]>([]);
  const [dateDailyValue, setDateDailyValue] = useState<any[]>([]);

  const [dateMonthlyKey, setDateMonthlyKey] = useState<any[]>([]);
  const [dateMonthlyValue, setDateMonthlyValue] = useState<any[]>([]);

  const [cameras, setCameras] = useState<any[]>([]);
  const [dataNumber, setDataNumber] = useState<any[]>([]);
  const [dataCamera, setDataCamera] = useState<any>({
    Deer: 0,
    Hog: 0,
    Turkey: 0,
    Other: 0,
  });
  const [filters, setFilters] = useState<any>({
    camera_id: 0,
    category: 'Deer',
    endDate: '',
    startDate: '',
  });

  useEffect(() => {
    console.log(filters);
    if (filters) {
      setLoadingSummary(true);
      setLoadingWeather(true);
      setLoadingDate(true);
      Api.SUB_ACTIVITY({ user_id: profile.user_id, camera_id: filters.camera_id, tag: filters.tag, category: filters.category, startDate: filters.startDate, endDate: filters.endDate }).then(((res: any) => {
        const result = JSON.parse(res.text);
        console.log(result);
        setCameras(result.cameraNames);

        const data: any = [];
        Object.keys(result.number).forEach((key) => {
          const name = result.cameraNames[key] || '';
          if (name == '') { return; }
          let count = result.number[key].Deer || 0;
          if (filters.category == 'Hog') {
            count = result.number[key].Hog || 0;
          }
          if (filters.category == 'Turkey') {
            count = result.number[key].Turkey || 0;
          }
          if (filters.category == 'People') {
            count = result.number[key].People || 0;
          }
          if (filters.category == 'Vehicle') {
            count = result.number[key].Vehicle || 0;
          }
          if (filters.category == 'Predator') {
            count = result.number[key].Predator || 0;
          }
          if (filters.category == 'Other') {
            count = result.number[key].Other || 0;
          }
          data.push({ name, count });
        });
        setDataNumber(data);

        setDataCamera({
          Deer: result.total.Deer,
          Hog: result.total.Hog,
          Turkey: result.total.Turkey,
          Other: result.total.Other,
        });
        setWeatherMoon(result.moon);
        setWeatherPressure(result.pressure);
        setWeatherTemp(result.temp);
        setWeatherWindDir(result.wind_dir);
        setWeatherWindSpeed(result.wind_speed);

        setDateDailyKey(result.daily.key);
        setDateDailyValue(result.daily.value);
        setDateMonthlyKey(result.monthly.key);
        setDateMonthlyValue(result.monthly.value);

        setLoadingSummary(false);
        setLoadingWeather(false);
        setLoadingDate(false);
      })).catch((error: any) => {
        console.log('error ===>', error);
        setLoadingSummary(false);
        setLoadingWeather(false);
        setLoadingDate(false);
      });
    }
  }, [filters]);

  const handleFilters = (arg) => {
    setFilters(arg);
  };

  const handleRightFilter = (willShow) => {
    setShowLeftFilter(willShow);
  };

  const capitalizeFirstLetter = (string) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return '';
  };
  return (
    <>
      <Row>
        {xs && (
          <Drawer
            title="Filter"
            width={300}
            onClose={() => handleRightFilter(false)}
            visible={showLeftFilter}
            zIndex={900}
            handler={
              (
                <div className={classes.drawerHandle}>
                  {
                    showLeftFilter ? <CloseOutlined onClick={() => handleRightFilter(false)} /> : <FilterOutlined onClick={() => handleRightFilter(true)} />
                  }
                </div>
              )
            }
          >
            <SubActivityFilter
              handleFilters={(arg) => handleFilters(arg)}
            />
          </Drawer>
        )}
        <Col>
          <div className={classes.content} style={{ padding: md ? 16 : 0 }}>
            {md && (
              <Col>
                <div style={{ marginBottom: '8px' }}>
                  <SubActivityFilter
                    handleFilters={(arg) => handleFilters(arg)}
                  />
                </div>
              </Col>
            )}
            <Row>
              <Col md={12} xs={24} style={{ paddingRight: md ? 8 : 0 }}>
                <Card title="Summary" className={classes.activityCard} headStyle={{ backgroundColor: '' }}>
                  <Spin spinning={loadingSummary} size="large">
                    <Row>
                      <Col xs={24} md={24}>
                        <ChartCamera data={dataCamera} />
                      </Col>
                    </Row>
                  </Spin>
                </Card>
                <Card title="Camera Activity" className={classes.activityCard} headStyle={{ backgroundColor: '' }}>
                  <Spin spinning={loadingSummary} size="large">
                    <Row>
                      <Col xs={24} md={24}>
                        <ChartSummaryBar data={dataNumber} cameras={cameras} color={Res.categoryColors[capitalizeFirstLetter(filters.category)]} color_alpha={Res.categoryColorsAlpha[capitalizeFirstLetter(filters.category)]} />
                      </Col>
                    </Row>
                  </Spin>
                </Card>
                <Card title="Monthly Activity" className={classes.activityCard}>
                  <Spin spinning={loadingDate} size="large">
                    <Row>
                      <Col xs={24}>
                        <ChartBesttimeLine data={dateDailyValue} color={Res.categoryColors[capitalizeFirstLetter(filters.category)]} color_alpha={Res.categoryColorsAlpha[capitalizeFirstLetter(filters.category)]} label="Daily" labels={dateDailyKey} />
                      </Col>
                      <Col xs={24}>
                        <ChartBesttimeLine data={dateMonthlyValue} color={Res.categoryColors[capitalizeFirstLetter(filters.category)]} color_alpha={Res.categoryColorsAlpha[capitalizeFirstLetter(filters.category)]} label="Monthly" labels={dateMonthlyKey} />
                      </Col>
                    </Row>
                  </Spin>
                </Card>
              </Col>
              <Col md={12} xs={24} style={{ paddingLeft: md ? 8 : 0 }}>
                <Card title="Weather Based Activity" className={classes.activityCard}>
                  <Spin spinning={loadingWeather} size="large">
                    <Row>
                      <Col xs={24}>
                        <ChartBesttimeLine data={weatherTemp} color={Res.categoryColors[capitalizeFirstLetter(filters.category)]} color_alpha={Res.categoryColorsAlpha[capitalizeFirstLetter(filters.category)]} label="Temperature" labels={LABELS_TEMP} />
                      </Col>
                      <Col xs={24}>
                        <ChartBesttimeLine data={weatherWindDir} color={Res.categoryColors[capitalizeFirstLetter(filters.category)]} color_alpha={Res.categoryColorsAlpha[capitalizeFirstLetter(filters.category)]} label="Wind Direction" labels={LABELS_WIND_DIR} />
                      </Col>
                      <Col xs={24}>
                        <ChartBesttimeLine data={weatherWindSpeed} color={Res.categoryColors[capitalizeFirstLetter(filters.category)]} color_alpha={Res.categoryColorsAlpha[capitalizeFirstLetter(filters.category)]} label="Wind Speed" labels={LABELS_WIND_SPEED} />
                      </Col>
                      <Col xs={24}>
                        <ChartBesttime data={weatherMoon} color={Res.categoryColors[capitalizeFirstLetter(filters.category)]} color_alpha={Res.categoryColorsAlpha[capitalizeFirstLetter(filters.category)]} label="Moon Phase" labels={LABELS_MOON} />
                      </Col>
                      <Col xs={24}>
                        <ChartBesttimeLine data={weatherPressure} color={Res.categoryColors[capitalizeFirstLetter(filters.category)]} color_alpha={Res.categoryColorsAlpha[capitalizeFirstLetter(filters.category)]} label="Barometric Pressure" labels={LABELS_PRESSURE} />
                      </Col>
                    </Row>
                  </Spin>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SubActivityPage;
