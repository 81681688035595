export const colorDeer = 'rgba(0, 166, 90, 1)';
export const colorHog = 'rgba(227, 197, 103 , 1)';
export const colorTurkey = 'rgba(135,115,72, 1)';
export const colorPeople = 'rgba(105,24,180, 1)';
export const colorVehicle = 'rgba(213,39,183, 1)';
export const colorPredator = 'rgba(128,0,21, 1)';
export const colorOther = 'rgba(0,115,183, 1)';
export const colorBestTime = 'rgba(255, 103, 0, 1)';

export const colorDeer_alpha = 'rgba(0, 166, 90, 0.2)';
export const colorHog_alpha = 'rgba(227, 197, 103 , 0.2)';
export const colorTurkey_alpha = 'rgba(135,115,72, 0.2)';
export const colorPeople_alpha = 'rgba(105,24,180, 0.2)';
export const colorVehicle_alpha = 'rgba(213,39,183, 0.2)';
export const colorPredator_alpha = 'rgba(128,0,21, 0.2)';
export const colorOther_alpha = 'rgba(0,115,183, 0.2)';
export const colorBestTime_alpha = 'rgba(255, 103, 0, 0.2)';

export const colorDeer_alpha2 = 'rgba(0, 166, 90, 0.5)';
export const colorHog_alpha2 = 'rgba(227, 197, 103 , 0.5)';
export const colorTurkey_alpha2 = 'rgba(135,115,72, 0.5)';
export const colorPeople_alpha2 = 'rgba(105,24,180, 0.5)';
export const colorVehicle_alpha2 = 'rgba(213,39,183, 0.5)';
export const colorPredator_alpha2 = 'rgba(128,0,21, 0.5)';
export const colorOther_alpha2 = 'rgba(0,115,183, 0.5)';
export const colorBestTime_alpha2 = 'rgba(255, 103, 0, 0.5)';

export const categoryColors = {
  All: colorBestTime,
  Deer: colorDeer,
  Hog: colorHog,
  Turkey: colorTurkey,
  People: colorPeople,
  Vehicle: colorVehicle,
  Predator: colorPredator,
  Other: colorOther,
};
export const categoryColorsAlpha = {
  All: colorBestTime_alpha,
  Deer: colorDeer_alpha,
  Hog: colorHog_alpha,
  Turkey: colorTurkey_alpha,
  People: colorPeople_alpha,
  Vehicle: colorVehicle_alpha,
  Predator: colorPredator_alpha,
  Other: colorOther_alpha,
};
export const categoryColorsAlpha2 = {
  All: colorBestTime_alpha2,
  Deer: colorDeer_alpha2,
  Hog: colorHog_alpha2,
  Turkey: colorTurkey_alpha2,
  People: colorPeople_alpha2,
  Vehicle: colorVehicle_alpha2,
  Predator: colorPredator_alpha2,
  Other: colorOther_alpha2,
};

export const categoryColorArray = [
  colorDeer,
  colorHog,
  colorTurkey,
  colorPeople,
  colorVehicle,
  colorPredator,
  colorOther,
];
export const categoryColorsAlphaArray = [
  colorDeer_alpha,
  colorHog_alpha,
  colorTurkey_alpha,
  colorPeople_alpha,
  colorVehicle_alpha,
  colorPredator_alpha,
  colorOther_alpha,
];
