import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Grid, Radio, Row, Spin } from 'antd';
import * as Api from 'api';
import ForecastPredictionChart from 'components/ForecastPredictionChart';
import PredictionFilter from 'components/PredictionFilter';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useApi from 'redux/api/api.hook';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

const PredictionPage = () => {
  const { showLeftFilter, setShowLeftFilter } = useApi();
  const { md, lg, xl, xxl } = useBreakpoint();
  const [loading, setLoading] = useState(false);

  const [forecastData, setForecastdata] = useState<any[]>([]);

  const loadData = (new_filters) => {
    setLoading(true);

    Api.FORECAST_PREDICT({ camera_id: new_filters.camera_id, category: new_filters.category, startDate: new_filters.startDate, endDate: new_filters.endDate, dateKind: new_filters.dateKind, tag: new_filters.tag, params: new_filters.params_for_api }).then(((res: any) => {
      const data = JSON.parse(res.text);
      setLoading(false);
      setForecastdata(data.data);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const handleRightFilter = (willShow) => {
    setShowLeftFilter(willShow);
  };

  return (
    <>
      <Row>
        {md && (
          <Col md={6} xxl={4}>
            <div className={classes.imageFilter}>
              <PredictionFilter
                onPredit={(arg) => loadData(arg)}
              />
            </div>
          </Col>
        )}
        {!md && (
          <Drawer
            title="Filter"
            width={300}
            onClose={() => handleRightFilter(false)}
            visible={showLeftFilter}
            zIndex={900}
            handler={
              (
                <div className={classes.drawerHandle}>
                  {
                    showLeftFilter ? <CloseOutlined onClick={() => handleRightFilter(false)} /> : <FilterOutlined onClick={() => handleRightFilter(true)} />
                  }
                </div>
              )
            }
          >
            <PredictionFilter
              onPredit={(arg) => loadData(arg)}
            />
          </Drawer>
        )}
        <Col md={18} xs={24} xxl={20}>
          <Spin spinning={loading} size="large" wrapperClassName={classes.content}>
            {forecastData && forecastData.length > 0 && xxl && (
              <div>
                <Row>
                  <Col xxl={8}>
                    <ForecastPredictionChart record={forecastData[0]} />
                    <ForecastPredictionChart record={forecastData[3]} />
                    <ForecastPredictionChart record={forecastData[6]} />
                  </Col>
                  <Col xxl={8}>
                    <ForecastPredictionChart record={forecastData[1]} />
                    <ForecastPredictionChart record={forecastData[4]} />
                  </Col>
                  <Col xxl={8}>
                    <ForecastPredictionChart record={forecastData[2]} />
                    <ForecastPredictionChart record={forecastData[5]} />
                  </Col>
                </Row>
              </div>
            )}
            {forecastData && forecastData.length > 0 && !xxl && md && (
              <div>
                <Row>
                  <Col md={12}>
                    <ForecastPredictionChart record={forecastData[0]} />
                    <ForecastPredictionChart record={forecastData[2]} />
                    <ForecastPredictionChart record={forecastData[4]} />
                    <ForecastPredictionChart record={forecastData[6]} />
                  </Col>
                  <Col md={12}>
                    <ForecastPredictionChart record={forecastData[1]} />
                    <ForecastPredictionChart record={forecastData[3]} />
                    <ForecastPredictionChart record={forecastData[5]} />
                  </Col>
                </Row>
              </div>
            )}
            {forecastData && forecastData.length > 0 && !md && (
              <div>
                <ForecastPredictionChart record={forecastData[0]} />
                <ForecastPredictionChart record={forecastData[1]} />
                <ForecastPredictionChart record={forecastData[2]} />
                <ForecastPredictionChart record={forecastData[3]} />
                <ForecastPredictionChart record={forecastData[4]} />
                <ForecastPredictionChart record={forecastData[5]} />
                <ForecastPredictionChart record={forecastData[6]} />
              </div>
            )}
          </Spin>
        </Col>
      </Row>
    </>
  );
};

export default PredictionPage;
