import { QuestionCircleOutlined } from '@ant-design/icons';
import { Battery0Bar, Battery2Bar, Battery4Bar, Battery6Bar, BatteryFull, SignalCellular0Bar, SignalCellular1Bar, SignalCellular2Bar, SignalCellular3Bar, SignalCellular4Bar } from '@mui/icons-material';
import { Button, Card, Col, Collapse, Divider, Form, Input, notification, Row, Select, Space, Switch, TimePicker, Tooltip } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import * as Api from 'api';
import AttComponent from 'components/cameraSimbols/AttComponent';
import BatteryComponent from 'components/cameraSimbols/BatteryComponent';
import CameraVersion from 'components/cameraSimbols/CameraVersion';
import SignalComponent from 'components/cameraSimbols/SignalComponent';
import TypeComponent from 'components/cameraSimbols/TypeComponent';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import classes from './style.module.scss';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const format = 'HH:mm';

const optionsCameraMode = [
  { value: 1, label: 'Photo (default)' },
  { value: 2, label: 'Video' },
  { value: 3, label: 'PIC+Video' },
];

const optionsMultiShot = [
  { value: 1, label: '1 Picture (default)' },
  { value: 2, label: '2 Pictures' },
  { value: 3, label: '3 Pictures' },
  { value: 4, label: '4 Pictures' },
  { value: 5, label: '5 Pictures' },
];

const optionsPictureSize = [
  { value: 3, label: '5M (default)' },
  { value: 2, label: '8M' },
  { value: 1, label: '12M' },
];

const optionsPirSensitivity = [
  { value: 0, label: 'High (default)' },
  { value: 1, label: 'Middle' },
  { value: 2, label: 'Low' },
];

const optionsCellular = [
  { value: 0, label: 'Once a Day (default)' },
  { value: 1, label: 'Always Available' },
];

const optionsNightMode = [
  { value: 1, label: 'Max.Range' },
  { value: 2, label: 'Balanced (default)' },
  { value: 3, label: 'Min.Blur' },
];

const CameraRemoteBig = (props) => {
  const [form] = Form.useForm();
  const { md } = useBreakpoint();
  const [switchTimeLapse, setSwitchTimeLapse] = useState(true);
  const [burstModeEnabled, setBurstModeEnabled] = useState(true);
  const [workTimer1, setWorkTimer1] = useState(true);
  const [workTimer2, setWorkTimer2] = useState(true);
  const [deviceSetting, setDeviceSetting] = useState({
    camera_mode: '',
    burst_mode: '',
    motion_sensitivity: '',
    picture_size: '',
    camera_check_int: '',
    night_mode: '',
    delay: '',
    camera_name: '',
    time_lapse: '',
    work_timer1: '',
    work_timer2: '',
    sd_card: 0,
    sd_card_max: 0,
  });

  useEffect(() => {
    console.log('camera', props.device);

    if (props.device.camera_mode == 2) {
      setBurstModeEnabled(false);
      form.setFieldsValue({ multi_shot: 1 });
    } else {
      setBurstModeEnabled(true);
    }

    let lapse = props.device.time_lapse;
    if (lapse == 'OFF') {
      setSwitchTimeLapse(false);
      lapse = '00:00:00';
    } else {
      setSwitchTimeLapse(true);
    }

    let work_timer1 = [moment('00:00', format), moment('00:00', format)];
    if (props.device.work_timer1 == 'OFF') {
      setWorkTimer1(false);
    } else {
      setWorkTimer1(true);
      const work_time = props.device.work_timer1.split('-');
      work_timer1 = [moment(work_time[0], format), moment(work_time[1], format)];
    }

    let work_timer2 = [moment('00:00', format), moment('00:00', format)];
    if (props.device.work_timer2 == 'OFF') {
      setWorkTimer2(false);
    } else {
      setWorkTimer2(true);
      const work_time = props.device.work_timer2.split('-');
      work_timer2 = [moment(work_time[0], format), moment(work_time[1], format)];
    }
    form.setFieldsValue({ ...props.device, delayMoment: moment(props.device.delay ? props.device.delay : '00:00:00', 'HH:mm:ss'), timeLapseMoment: moment(lapse, 'HH:mm:ss'), workTimerMoment1: work_timer1, workTimerMoment2: work_timer2 });
    http_load_device_setting(props.camera.IMEI);
  }, [props.device]);

  const onFinish = (values) => {
    let delay = '00:00:00';
    if (values.delayMoment) { delay = values.delayMoment.format('HH:mm:ss'); }
    if (delay < '00:00:05') {
      delay = '00:00:05';
      form.setFieldsValue({ delayMoment: moment(delay, 'HH:mm:ss') });
    }

    let time_lapse = 'OFF';
    if (switchTimeLapse) {
      time_lapse = '00:00:00';
      if (values.timeLapseMoment) { time_lapse = values.timeLapseMoment.format('HH:mm:ss'); }
      if (time_lapse < '00:00:03') {
        time_lapse = '00:00:03';
        form.setFieldsValue({ timeLapseMoment: moment(time_lapse, 'HH:mm:ss') });
      }
    }
    let work_timer1 = 'OFF';
    if (workTimer1) {
      work_timer1 = '00:00-00:00';
      if (values.workTimerMoment1) {
        const time1 = values.workTimerMoment1[0].format(format);
        const time2 = values.workTimerMoment1[1].format(format);
        work_timer1 = `${time1}-${time2}`;
      }
    }

    let work_timer2 = 'OFF';
    if (workTimer2) {
      work_timer2 = '00:00-00:00';
      if (values.workTimerMoment2) {
        const time1 = values.workTimerMoment2[0].format(format);
        const time2 = values.workTimerMoment2[1].format(format);
        work_timer2 = `${time1}-${time2}`;
      }
    }

    if (values.camera_mode == 2) {
      form.setFieldsValue({ multi_shot: 1 });
      values.multi_shot = 1;
    }

    console.log(values);
    const params = {
      IMEI: props.device.IMEI,
      multi_shot: values.multi_shot,
      pir_sensitivity: values.pir_sensitivity,
      picture_size: values.picture_size,
      sms_remote: values.sms_remote,
      delay,
      time_lapse,
      camera_mode: values.camera_mode,
      night_mode: values.night_mode,
      work_timer1,
      work_timer2,
    };
    Api.REMOTE_CONTROL_STANDARD(params).then(((res: any) => {
      notification.open({
        message: 'Your new settings have been sent to your camera and will take effect the next time your camera connects to the server.',
      });
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
    setSwitchTimeLapse(checked);
    if (checked) {
      form.setFieldsValue({ timeLapseMoment: moment('00:00:03', 'HH:mm:ss') });
    }
  };

  const onChangeCameraMode = (value) => {
    console.log(value);
    if (value == 2) {
      setBurstModeEnabled(false);
      form.setFieldsValue({ multi_shot: 1 });
    } else {
      setBurstModeEnabled(true);
    }
  };

  const onGetPicture = () => {
    const params = {
      IMEI: props.device.IMEI,
    };
    Api.REMOTE_CONTROL_GETAPICKTURE_BIG(params).then((res: any) => {
      notification.open({
        message: res.text,
      });
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const getLabel = (value, options) => {
    for (let i = 0; i < options.length; i++) {
      if (parseInt(value, 10) == options[i].value) {
        return options[i].label;
      }
    }
    return '';
  };

  const http_load_device_setting = (IMEI) => {
    Api.REMOTE_CONTROL_DEVICE_SETTING(IMEI).then(((data: any) => {
      const res = JSON.parse(data.text);
      console.log(res);
      if (res.result == 'OK') {
        const new_device = {
          camera_mode: getLabel(res.device.camera_mode, optionsCameraMode),
          burst_mode: getLabel(res.device.multi_shot, optionsMultiShot),
          motion_sensitivity: getLabel(res.device.pir_sensitivity, optionsPirSensitivity),
          picture_size: getLabel(res.device.picture_size, optionsPictureSize),
          camera_check_int: getLabel(res.device.sms_remote, optionsCellular),
          night_mode: getLabel(res.device.night_mode, optionsNightMode),
          delay: res.device.delay,
          camera_name: res.device.camera_name,
          time_lapse: res.device.time_lapse,
          work_timer1: res.device.work_timer1,
          work_timer2: res.device.work_timer2,
          sd_card: res.device.sd_card,
          sd_card_max: res.device.sd_card_max,
        };
        setDeviceSetting(new_device);
      }
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  return (
    <>
      <Form
        {...layout}
        form={form}
        name="basic"
        onFinish={onFinish}
      >
        <Card className={classes.card}>
          <Row>
            <Col md={12} xs={24}>
              <Form.Item
                label="Camera Mode"
                name="camera_mode"
                tooltip={{ title: 'Choose whether you wish to receive videos, pictures or both videos and pictures. Please be aware that receiving videos will use significantly more data than just pictures.', placement: 'bottom' }}
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsCameraMode}
                  onChange={onChangeCameraMode}
                />
              </Form.Item>
              <Form.Item
                label="Burst Mode"
                name="multi_shot"
                tooltip={{ title: 'Choose how many pictures you would like the camera to take with each motion trigger. This only works in Photo mode.', placement: 'bottom' }}
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsMultiShot}
                  disabled={!burstModeEnabled}
                />
              </Form.Item>
              <Form.Item
                label="Picture Size"
                name="picture_size"
                tooltip={{ title: 'This is the size of the picture that is saved on the SD Card. This will not affect the size of the images transmitted to HuntControl.', placement: 'bottom' }}
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsPictureSize}
                />
              </Form.Item>
              <Form.Item
                label="Night Mode"
                name="night_mode"
                tooltip={{ title: 'This controls the shutter speed at night. Max Range will illuminate things further away, but it will tend to create more blur. Minimum Blur will reduce the amount of blurriness in the photo, but it will reduce the range of the flash at night. Balanced is good for most situations.', placement: 'bottom' }}
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsNightMode}
                />
              </Form.Item>
              <Form.Item
                label="Camera Check In"
                name="sms_remote"
                tooltip={{ title: 'Once a Day - Best Battery Life, Up to 24hrs to execute remote control commands. Always Available - Instantly Execute commands, significant battery drain. Recommended only for those with solar panels or larger battery adapters.', placement: 'bottom' }}
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsCellular}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Delay"
                name="delayMoment"
                tooltip={{ title: 'This is the amount of time that the camera will wait between taking pictures.', placement: 'bottom' }}
              >
                <TimePicker className={classes.control} defaultValue={moment('00:00:00', 'HH:mm:ss')} size="large" />
              </Form.Item>
              <Form.Item
                label="Time Lapse:"
                className={classes.divTimeLapse}
                tooltip={{ title: 'Allows you to take pictures at an interval, regardless of the motion sensor. You can set the interval as low as 5 seconds or as high as 23 hrs and 59 minutes.', placement: 'bottom' }}
              >
                <Input.Group compact>
                  <Form.Item
                    label=""
                    name="timeLapseMoment"
                  >
                    <TimePicker
                      className={classes.control}
                      size="large"
                      defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                      disabled={!switchTimeLapse}
                    />
                  </Form.Item>
                  &nbsp;
                  <Form.Item
                    label=""
                  >
                    <Switch checked={switchTimeLapse} onChange={onChange} />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item
                label="Motion Sensitivity"
                name="pir_sensitivity"
                tooltip={{ title: 'High - more sensitive to motion. Low - least sensitive to motion.', placement: 'bottom' }}
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsPirSensitivity}
                />
              </Form.Item>
              <Form.Item
                label="Work Timer1"
                className={classes.divTimeLapse}
                tooltip={{ title: 'Select which hours during the day that you would like the camera to be active. Do not set this if you wish the camera to always be active.', placement: 'bottom' }}
              >
                <Input.Group compact>
                  <Form.Item
                    label=""
                    name="workTimerMoment1"
                  >
                    <TimePicker.RangePicker
                      className={classes.control}
                      size="large"
                      defaultOpenValue={moment('00:00', format)}
                      disabled={!workTimer1}
                      format={format}
                    />
                  </Form.Item>
                  &nbsp;
                  <Form.Item
                    label=""
                  >
                    <Switch
                      checked={workTimer1}
                      onChange={(checked) => {
                        console.log(`switch to ${checked}`);
                        setWorkTimer1(checked);
                        if (checked) {
                          form.setFieldsValue({ workTimerMoment1: moment('00:00:03', 'HH:mm:ss') });
                        }
                      }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item
                label="Work Timer2"
                className={classes.divTimeLapse}
                tooltip={{ title: 'Select which hours during the day that you would like the camera to be active. Do not set this if you wish the camera to always be active.', placement: 'bottom' }}
              >
                <Input.Group compact>
                  <Form.Item
                    label=""
                    name="workTimerMoment2"
                  >
                    <TimePicker.RangePicker
                      className={classes.control}
                      size="large"
                      defaultOpenValue={moment('00:00', format)}
                      disabled={!workTimer2}
                      format={format}
                    />
                  </Form.Item>
                  &nbsp;
                  <Form.Item
                    label=""
                  >
                    <Switch
                      checked={workTimer2}
                      onChange={(checked) => {
                        console.log(`switch to ${checked}`);
                        setWorkTimer2(checked);
                        if (checked) {
                          form.setFieldsValue({ workTimer2Moment: moment('00:00:03', 'HH:mm:ss') });
                        }
                      }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <div className={classes.divFooter}>
            <Button type="primary" style={{ float: 'right' }} htmlType="submit">
              Send
            </Button>
          </div>
          <Divider />
          <div className={classes.divBottom}>
            <Space>
              <Tooltip title="This will tell the camera to send you a picture the next time that the camera checks in. If your camera is set to Daily Cellular Mode then it may take up to 24hrs to get the image. If your camera is set to Instant Cellular Mode then it should send the picture in a few minutes at most. The picture will show in your image gallery.">
                <Button type="primary" onClick={onGetPicture}>
                  Get A Picture
                </Button>
              </Tooltip>
            </Space>
          </div>
        </Card>
      </Form>
      <Collapse defaultActiveKey={[]}>
        <Collapse.Panel header="Settings Received From Camera" key="1">
          <div className={classes.divBottomHeader}>
            <div className={classes.divTitle}>
              <h1 className={classes.labelTitle}>{props.camera.name}</h1>
              <Tooltip placement="leftTop" title="Below you can see the most recent settings on your camera. These may be different from the settings above. The cameras check in with HuntControl based on your settings above.Cameras in Always Available will get your commands quickly and update quickly, but this requires much power and will drain AA batteries. Cameras in Daily will get your commands only once a day, so there may be a delay between the commands being sent and the settings being changed on the camera. If your camera is in Daily, please allow up to 24 hrs for the commands to process.The Last Check In time shows the last time the camera checked for new commands from HuntControl.">
                <QuestionCircleOutlined style={{ color: '#DC6300' }} />
              </Tooltip>
            </div>
            <div className={classes.divFirmware}>
              <Row className={classes.labelFirmware}>
                <Col md={12} xs={24}>
                  <span>Last Updated: {props.device.last_text_uploaded_at > 1000 ? moment(props.device.last_text_uploaded_at * 1000).format('hh:mm A - MMM D, Y') : ''}</span>
                </Col>
                <Col md={12} xs={24}>
                  <span>Firmware Version: {props.device.firmware_version}</span>
                </Col>
              </Row>
            </div>
          </div>
          <Divider />
          <div className={classes.divBottomContent}>
            <div className={classes.divCameraType}>
              <CameraVersion detail={props.device} size={md ? 150 : 60} />
              <AttComponent detail={props.device} />
            </div>
            {md && (
              <div className={classes.divText}>
                <h3>Settings Received From Camera:</h3>
                <table className={classes.border_none}>
                  <tr>
                    <td className={classes.td_title}>Camera Mode:</td>
                    <td className={classes.td_value}>{deviceSetting.camera_mode}</td>
                    <td className={classes.td_title}>Delay: </td>
                    <td className={classes.td_value}>{deviceSetting.delay}</td>
                  </tr>
                  <tr>
                    <td className={classes.td_title}>Burst Mode:</td>
                    <td className={classes.td_value}>{deviceSetting.burst_mode}</td>
                    <td className={classes.td_title}>Time Lapse: </td>
                    <td className={classes.td_value}>{deviceSetting.time_lapse}</td>
                  </tr>
                  <tr>
                    <td className={classes.td_title}>Picture Size:</td>
                    <td className={classes.td_value}>{deviceSetting.picture_size}</td>
                    <td className={classes.td_title}>Motion Sensitivity: </td>
                    <td className={classes.td_value}>{deviceSetting.motion_sensitivity}</td>
                  </tr>
                  <tr>
                    <td className={classes.td_title}>Night Mode: </td>
                    <td className={classes.td_value}>{deviceSetting.night_mode}</td>
                    <td className={classes.td_title}>Work Timer1: </td>
                    <td className={classes.td_value}>{deviceSetting.work_timer1}</td>
                  </tr>
                  <tr>
                    <td className={classes.td_title}>Camera Check In: </td>
                    <td className={classes.td_value}>{deviceSetting.camera_check_int}</td>
                    <td className={classes.td_title}>Work Timer2: </td>
                    <td className={classes.td_value}>{deviceSetting.work_timer2}</td>
                  </tr>
                </table>
              </div>
            )}
            <div className={classes.divImages}>
              <div className={classes.divCard}>
                <img className={classes.image} alt="test" src="/icons_camera/sdcard_icon.png" />
                <p>{(props.device.sd_card_max - props.device.sd_card)}GB/{props.device.sd_card_max}GB</p>
                <p>{Math.round(((props.device.sd_card_max - props.device.sd_card) * 100) / (props.device.sd_card_max + 0.01))}%</p>
              </div>
              <BatteryComponent detail={props.device} fontSize="large" />
              <div className={classes.iconSignal}>
                <SignalComponent detail={props.device} fontSize="large" />
              </div>
            </div>
          </div>
          {!md && (
            <div className={classes.divText}>
              <table className={classes.border_none}>
                <tr>
                  <td className={classes.td_title}>Camera Mode:</td>
                  <td className={classes.td_value}>{deviceSetting.camera_mode}</td>
                  <td className={classes.td_title}>Delay: </td>
                  <td className={classes.td_value}>{deviceSetting.delay}</td>
                </tr>
                <tr>
                  <td className={classes.td_title}>Burst Mode:</td>
                  <td className={classes.td_value}>{deviceSetting.burst_mode}</td>
                  <td className={classes.td_title}>Time Lapse: </td>
                  <td className={classes.td_value}>{deviceSetting.time_lapse}</td>
                </tr>
                <tr>
                  <td className={classes.td_title}>Picture Size:</td>
                  <td className={classes.td_value}>{deviceSetting.picture_size}</td>
                  <td className={classes.td_title}>Motion Sensitivity: </td>
                  <td className={classes.td_value}>{deviceSetting.motion_sensitivity}</td>
                </tr>
                <tr>
                  <td className={classes.td_title}>Night Mode: </td>
                  <td className={classes.td_value}>{deviceSetting.night_mode}</td>
                  <td className={classes.td_title}>Work Timer1: </td>
                  <td className={classes.td_value}>{deviceSetting.work_timer1}</td>
                </tr>
                <tr>
                  <td className={classes.td_title}>Camera Check In: </td>
                  <td className={classes.td_value}>{deviceSetting.camera_check_int}</td>
                  <td className={classes.td_title}>Work Timer2: </td>
                  <td className={classes.td_value}>{deviceSetting.work_timer2}</td>
                </tr>
              </table>
            </div>
          )}
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default CameraRemoteBig;
