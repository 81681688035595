import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Bar } from 'react-chartjs-2';
import * as Res from 'res';
import { Card } from 'antd';

am4core.useTheme(am4themes_animated);

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

interface props {
  data: any,
  // cameras: any,
}

const ChartCamera: React.FC<props> = (props) => {
  // const resultNames: any = [];
  // const resultDeer: any = [];
  // const resultHog: any = [];
  // const resultTurkey: any = [];
  // const resultOther: any = [];

  // Object.keys(props.data).forEach((key) => {
  //   const name = props.cameras[key] || '';
  //   if (name == '') { return; }
  //   resultNames.push(name);
  //   resultDeer.push(props.data[key].Deer || 0);
  //   resultHog.push(props.data[key].Hog || 0);
  //   resultTurkey.push(props.data[key].Turkey || 0);
  //   resultOther.push(props.data[key].Other || 0);
  // });

  const dataChart = {
    labels: [''],
    datasets: [
      {
        label: 'Deer',
        data: [props.data.Deer],
        backgroundColor: Res.colorDeer_alpha,
        borderColor: Res.colorDeer,
        borderWidth: 2,
      },
      {
        label: 'Hog',
        data: [props.data.Hog],
        backgroundColor: Res.colorHog_alpha,
        borderColor: Res.colorHog,
        borderWidth: 2,
      },
      {
        label: 'Turkey',
        data: [props.data.Turkey],
        backgroundColor: Res.colorTurkey_alpha,
        borderColor: Res.colorTurkey,
        borderWidth: 2,
      },
      {
        label: 'Pepple',
        data: [props.data.People],
        backgroundColor: Res.colorPeople_alpha,
        borderColor: Res.colorPeople,
        borderWidth: 2,
      },
      {
        label: 'Vehicle',
        data: [props.data.Vehicle],
        backgroundColor: Res.colorVehicle_alpha,
        borderColor: Res.colorVehicle,
        borderWidth: 2,
      },
      {
        label: 'Predator',
        data: [props.data.Predator],
        backgroundColor: Res.colorPredator_alpha,
        borderColor: Res.colorPredator,
        borderWidth: 2,
      },
      {
        label: 'Other',
        data: [props.data.Other],
        backgroundColor: Res.colorOther_alpha,
        borderColor: Res.colorOther,
        borderWidth: 2,
      },
    ],
  };

  return (
    <Card bodyStyle={{ padding: '8px' }}>
      <Bar data={dataChart} options={options} />
    </Card>
  );
};

export default ChartCamera;
