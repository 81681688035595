import React, { useEffect, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import * as Api from 'api';
import { Bar } from 'react-chartjs-2';
import useAuth from 'redux/auth/auth.hook';
import * as Res from 'res';
import { DatePicker, Select, Form, Spin } from 'antd';

import classes from './style.module.scss';

am4core.useTheme(am4themes_animated);

const { Option } = Select;

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const DashboardChartCamera = () => {
  const { auth: { profile } } = useAuth();
  const [dataCamera, setDataCamera] = useState<any>([]);
  const [dataBesttime, setDataBesttime] = useState<any>([]);
  const [dataBestday, setDataBestday] = useState<any>([]);
  const [loadingCamera, setLoadingCamera] = useState(false);
  const [loadingBesttime, setLoadingBesttime] = useState(false);
  const [loadingDay, setLoadingDay] = useState(false);
  const [form] = Form.useForm();

  const [cameraArr, setCameraArr] = useState<any[]>([]);
  const [filterOptions, setFilterOption] = useState({
    camera_id: 0,
    startDate: '',
    endDate: '',
    month: '',
    video_image: '',
  });

  useEffect(() => {
    if (profile) {
      Api.CAMERA_GET_ALL().then(((res: any) => {
        const result = JSON.parse(res.text);
        setCameraArr(result.cameras);
        const newFilter = { ...filterOptions, camera_id: 0 };
        setFilterOption(newFilter);
        form.setFieldsValue(newFilter);
        loadData(newFilter);
        loadDataMonth(newFilter);
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
    }
  }, [profile]);

  const onValuesChange = (values, allvalues) => {
    const newFilter = { ...filterOptions, ...allvalues };
    setFilterOption(newFilter);
    loadData(newFilter);
    loadDataMonth(newFilter);
  };

  const onChangeMonth = (date, dateString) => {
    const newFilter = { ...filterOptions, month: dateString };
    setFilterOption(newFilter);
    loadDataMonth(newFilter);
  };

  const loadData = (newFilter) => {
    setLoadingCamera(true);
    Api.DASHBOARD_CHART_CAMERA({ camera_id: newFilter.camera_id }).then(((res: any) => {
      const result = JSON.parse(res.text);
      setLoadingCamera(false);

      const dataChart = {
        labels: [''],
        datasets: [
          {
            label: 'Deer',
            data: result.deers,
            backgroundColor: Res.colorDeer_alpha,
            borderColor: Res.colorDeer,
            borderWidth: 1,
          },
          {
            label: 'Hog',
            data: result.hogs,
            backgroundColor: Res.colorHog_alpha,
            borderColor: Res.colorHog,
            borderWidth: 1,
          },
          {
            label: 'Turkey',
            data: result.turkeys,
            backgroundColor: Res.colorTurkey_alpha,
            borderColor: Res.colorTurkey,
            borderWidth: 1,
          },
          {
            label: 'Other',
            data: result.others,
            backgroundColor: Res.colorOther_alpha,
            borderColor: Res.colorOther,
            borderWidth: 1,
          },
        ],
      };
      setDataCamera(dataChart);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });

    setLoadingBesttime(true);
    Api.DASHBOARD_CHART_BESTTIME({ camera_id: newFilter.camera_id }).then(((res: any) => {
      const result = JSON.parse(res.text);
      setLoadingBesttime(false);
      const dataChart = {
        labels: ['12AM+', '3AM+', '6AM+', '9AM+', '12PM+', '3PM+', '6PM+', '9PM+'],
        datasets: [
          {
            label: 'Deer Images by Time',
            data: result.Deer,
            backgroundColor: Res.colorDeer_alpha,
            borderColor: Res.colorDeer,
            borderWidth: 1,
          },
        ],
      };
      setDataBesttime(dataChart);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const loadDataMonth = (newFilter) => {
    setLoadingDay(true);
    Api.DASHBOARD_CHART_MONTH({ camera_id: newFilter.camera_id, month: newFilter.month, category: 'deer' }).then(((res: any) => {
      const result = JSON.parse(res.text);
      setLoadingDay(false);
      const labels: string[] = [];
      for (let i = 1; i <= 31; i++) {
        labels.push(`${i}`);
      }
      const dataChart = {
        labels,
        datasets: [
          {
            label: 'Deer Images by Day',
            data: result.data,
            backgroundColor: Res.colorDeer_alpha,
            borderColor: Res.colorDeer,
            borderWidth: 1,
          },
        ],
      };
      setDataBestday(dataChart);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  return (
    <div>
      <div>
        <Form form={form} style={{ marginTop: 20 }} onValuesChange={onValuesChange}>
          <Form.Item label="Images by Camera" name="camera_id" className={classes.formInputWrapper}>
            <Select size="large">
              <Option key={0} value={0}>All</Option>
              {cameraArr.map((val: any) => (
                <Option key={val.id} value={val.id}>{val.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </div>
      <Spin spinning={loadingCamera} size="large">
        <Bar data={dataCamera} options={options} />
      </Spin>
      <Spin spinning={loadingBesttime} size="large">
        <Bar data={dataBesttime} options={options} />
      </Spin>
      <div>
        <Form.Item label="Year/Month:" className={classes.formInputWrapper}>
          <div>
            <DatePicker size="large" style={{ width: '100%' }} onChange={onChangeMonth} picker="month" />
          </div>
        </Form.Item>
      </div>
      <Spin spinning={loadingDay} size="large">
        <Bar data={dataBestday} options={options} />
      </Spin>
    </div>

  );
};

export default DashboardChartCamera;
