import { Card, Col, Grid, Row, Select, Space, Spin, Table, Tabs, notification } from 'antd';
import * as Api from 'api';
import WeatherChart from 'components/WeatherChart';
import WeatherChartWind from 'components/WeatherChartWind';
import React, { useEffect, useState } from 'react';
import useAuth from 'redux/auth/auth.hook';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

const WeatherPage = () => {
  const { auth: { profile } } = useAuth();
  const { md } = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [cameraID, setCameraID] = useState('0');
  const [selectedKey, setSelecedKey] = useState(0);
  const [cameraArr, setCameraArr] = useState<any[]>([]);
  const [dailyArray, setDailyArray] = useState<any>([]);
  const [dateData, setDateData] = useState<any>();
  const [chartSun, setChartSun] = useState<any>([]);
  const [chartTemp, setChartTemp] = useState<any>([]);
  const [chartWind, setChartWind] = useState<any>([]);
  const [chartWindDir, setChartWindDir] = useState<any>([]);
  const [chartWindDegree, setChartWindDegree] = useState<any>([]);
  const [chartPressure, setChartPressure] = useState<any>([]);
  const [chartRain, setChartRain] = useState<any>([]);
  const [chartHumidity, setChartHumidity] = useState<any>([]);

  useEffect(() => {
    if (profile) {
      Api.CAMERA_GET_ALL().then(((res: any) => {
        const result = JSON.parse(res.text);
        setCameraArr(result.cameras);
        if (result.cameras.length > 0) {
          setCameraID(result.cameras[0].id);
        }
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
    }
  }, [profile]);

  useEffect(() => {
    if (cameraID != '0') {
      setLoading(true);
      Api.FORECAST_WEATHER({ camera_id: cameraID }).then(((res: any) => {
        const data = JSON.parse(res.text);
        setLoading(false);
        if (data.success == false) {
          notification.open({
            message: data.error.info,
          });
          setDailyArray([]);
        } else {
          setDailyArray(data);
        }
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
    }
  }, [cameraID]);

  useEffect(() => {
    if (dailyArray.length > selectedKey) {
      const data = dailyArray[selectedKey];
      setDateData(data);
      const arrayTemp: number[] = [];
      const arrayWind: number[] = [];
      const arrayWindDir: string[] = [];
      const arrayWindDegree: number[] = [];
      const arrayPressure: number[] = [];
      const arrayRain: number[] = [];
      const arrayHumidity: number[] = [];
      const arraySun: string[] = [];
      for (let i = 0; i < data.hourly.length; i++) {
        const item = data.hourly[i];
        arrayTemp.push(item.temperature);
        arrayWind.push(item.wind_speed);
        arrayWindDir.push(item.wind_dir);
        arrayWindDegree.push(item.wind_degree);
        arrayPressure.push(item.pressure);
        arrayRain.push(item.chanceofrain);
        arrayHumidity.push(item.humidity);
        arraySun.push(item.weather_icon);
      }
      setChartTemp(arrayTemp);
      setChartPressure(arrayPressure);
      setChartWind(arrayWind);
      setChartWindDir(arrayWindDir);
      setChartWindDegree(arrayWindDegree);
      setChartRain(arrayRain);
      setChartHumidity(arrayHumidity);
      setChartSun(arraySun);
    }
  }, [selectedKey, dailyArray]);

  function dayOfWeekAsString(arg: string) {
    // eslint-disable-next-line no-var
    // eslint-disable-next-line radix
    const argValue = parseInt(arg) - 1;
    return ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'][argValue];
  }

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ];

  function getMonthName(arg: string) {
    // eslint-disable-next-line radix
    const argValue = parseInt(arg) - 1;
    if (argValue < 0 || argValue == null) return '';
    return monthNames[argValue];
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: '1',
      render: (_: any, record: any) => (
        <div className={classes.divDay}>
          <h5 className={classes.firstWeekday}>{dayOfWeekAsString(record.weekday)}</h5>
          <h5>{getMonthName(record.month)}/{record.day}</h5>
        </div>
      ),
    },
    {
      title: 'Moon',
      dataIndex: 'moon',
      key: '2',
      render: (_: any, record: any) => (
        <div>
          <img src={`/icons_moon_phase/${record.moon_phase}.png`} alt="" className={classes.img_moon} />
          <h5>{record.moon_phase}</h5>
        </div>
      ),
    },
    {
      title: 'Sun',
      dataIndex: 'sun',
      key: '3',
      render: (_: any, record: any) => (
        <div>
          <img src={record.weather_icon} alt="" className={classes.img_sun} />
          <br />
          {record.summary}
          <div>
            Rise: {record.sunrise}<br /> Set: {record.sunset}
          </div>
        </div>
      ),
    },
    {
      title: 'Weather',
      dataIndex: 'temp',
      key: '4',
      render: (_: any, record: any) => (
        <div>
          {record.weather_description}
          <br />
          {record.maxtemp}°F / {record.mintemp}°F
        </div>
      ),
    },
    {
      title: 'Humidity',
      dataIndex: 'humidity',
      key: '5',
      render: (_: any, record: any) => (
        <div>
          {record.humidity}%RH
        </div>
      ),
    },
    {
      title: 'Wind Speed/Dir',
      dataIndex: 'wind',
      key: '6',
      render: (_: any, record: any) => (
        <div>
          {record.wind_speed}MPH / {record.wind_dir}
        </div>
      ),
    },
  ];

  const columnsMobile = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: '1',
      render: (_: any, record: any) => (
        <div className={classes.divDay}>
          <h5 className={classes.firstWeekday}>{dayOfWeekAsString(record.weekday)}</h5>
          <h5>{getMonthName(record.month)}/{record.day}</h5>
        </div>
      ),
    },
    {
      title: 'Moon',
      dataIndex: 'moon',
      key: '2',
      render: (_: any, record: any) => (
        <div>
          <img src={`/icons_moon_phase/${record.moon_phase}.png`} alt="" className={classes.img_moon} />
          <h5>{record.moon_phase}</h5>
        </div>
      ),
    },
    {
      title: 'Sun',
      dataIndex: 'sun',
      key: '3',
      render: (_: any, record: any) => (
        <div>
          <img src={record.weather_icon} alt="" className={classes.img_sun} />
          <br />
          {record.summary}
        </div>
      ),
    },
    {
      title: 'Weather',
      dataIndex: 'temp',
      key: '4',
      render: (_: any, record: any) => (
        <div>
          {record.weather_description}
          <br />
          {record.maxtemp}°F / {record.mintemp}°F
        </div>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col md={12} xs={24}>
          <div className={classes.content} style={{ padding: md ? 16 : 0 }}>
            <Space>
              <div className={classes.formInputLabel}>Camera:&nbsp;</div>
              <Select
                className={classes.select}
                size="large"
                value={cameraID}
                onChange={(value) => {
                  setCameraID(value);
                }}
              >
                <Select.OptGroup label="Cameras">
                  {cameraArr.map((val: any) => (
                    <Select.Option key={val.id} value={val.id}>{val.name}</Select.Option>
                  ))}
                </Select.OptGroup>
              </Select>
            </Space>
            <Card bodyStyle={{ padding: '8px' }}>
              {dateData && (
                <div className={classes.divDateSummary}>
                  <div>
                    <img src={dateData.weather_icon} alt="" className={classes.img_sun} />
                  </div>
                  <div className={classes.divTemp}>
                    <b className={classes.Number}>{dateData.temperature}</b> <p>°F</p>
                  </div>
                  <div className={classes.divDetail}>
                    <div>
                      <p className={classes.Number}>Wind: {dateData.wind_dir} {dateData.wind_speed}rpm</p>
                      <p className={classes.Number}>Humidity: {dateData.humidity}%</p>
                    </div>
                  </div>
                  <div className={classes.divDateLocation}>
                    <div>
                      <p className={classes.location}>{dateData.location_name}</p>
                      <p className={classes.date}>({dayOfWeekAsString(dateData.weekday)}) {getMonthName(dateData.month)}/{dateData.day}</p>
                    </div>
                  </div>
                </div>
              )}
              <Tabs defaultActiveKey="1" type="card">
                <Tabs.TabPane tab="Temperature" key="1">
                  <WeatherChart data={chartTemp} arraySunImages={chartSun} label="Fahrenheit" />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Wind" key="2">
                  <WeatherChartWind data={chartWind} arrayDegree={chartWindDegree} arrayDirection={chartWindDir} label="Miles/Hour" />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Pressure" key="3">
                  <WeatherChart data={chartPressure} arraySunImages={chartSun} label="MB" />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Humidity" key="4">
                  <WeatherChart data={chartHumidity} arraySunImages={chartSun} label="RH %" />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Chance Of Rain" key="5">
                  <WeatherChart data={chartRain} arraySunImages={chartSun} label="Chance Of Rain %" />
                </Tabs.TabPane>
              </Tabs>
            </Card>
          </div>
        </Col>
        <Col md={12} xs={24}>
          <div className={classes.content} style={{ padding: md ? 16 : 0 }}>
            <Spin spinning={loading} size="large">
              <Table
                rowSelection={{
                  selectedRowKeys: [selectedKey],
                  type: 'radio',
                  onChange: (selectedRowKeys: any[]) => {
                    setSelecedKey(selectedRowKeys[0]);
                    // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                  },
                }}
                dataSource={dailyArray}
                columns={md ? columns : columnsMobile}
                onRow={(record) => ({
                  onClick: () => {
                    setSelecedKey(record.key);
                  },
                })}
              />
            </Spin>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default WeatherPage;
