import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { Col, Drawer, Grid, Row, Spin } from 'antd';
import * as Api from 'api';
import ChartBesttime from 'components/chartBesttime';
import FilterBestTime from 'components/filterBestTime';
import ChartBesttimeLine from 'components/chartBesttimeLine';
import React, { useEffect, useState } from 'react';
import useApi from 'redux/api/api.hook';
import useAuth from 'redux/auth/auth.hook';
import * as Res from 'res';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

const BestTimePage = () => {
  const { showLeftFilter, setShowLeftFilter } = useApi();
  const { md, xs } = useBreakpoint();
  const [loadingBesttime, setLoadingBesttime] = useState(false);

  const { auth: { profile } } = useAuth();

  const [activity, setActivity] = useState<any>();
  const [filters, setFilters] = useState<any>({
    camera_id: 0,
    endDate: '',
    startDate: '',
    tag: '',
  });

  const categories = ['deer', 'hog', 'turkey', 'people', 'vehicle', 'predator', 'other'];

  useEffect(() => {
    console.log(filters);
    if (filters) {
      setLoadingBesttime(true);
      const cameras = filters.camera_id == 0 ? [] : [filters.camera_id];
      const tags = filters.tag == '' ? [] : [filters.tag];
      Api.CHART_BESTTIME({ cameras, fromDate: filters.startDate, toDate: filters.endDate, tags, categories: [] }).then(((res: any) => {
        const result = JSON.parse(res.text);
        console.log('CHART_BESTTIME', result);
        setActivity(result);
        setLoadingBesttime(false);
      })).catch((error: any) => {
        console.log('error ===>', error);
        setLoadingBesttime(false);
      });
    }
  }, [filters]);

  const handleFilters = (arg) => {
    console.log('filter => ', arg);
    setFilters(arg);
  };

  const handleRightFilter = (willShow) => {
    setShowLeftFilter(willShow);
  };

  return (
    <>
      <Row>
        {md && (
          <Col md={8} lg={6} xxl={4}>
            <div className={classes.imageFilter}>
              <FilterBestTime
                handleFilters={(arg) => handleFilters(arg)}
              />
            </div>
          </Col>
        )}
        {!md && (
          <Drawer
            title="Filter & Edit"
            width={300}
            onClose={() => handleRightFilter(false)}
            visible={showLeftFilter}
            zIndex={900}
            handler={
              (
                <div className={classes.drawerHandle}>
                  {
                    showLeftFilter ? <CloseOutlined onClick={() => handleRightFilter(false)} /> : <FilterOutlined onClick={() => handleRightFilter(true)} />
                  }
                </div>
              )
            }
          >
            <FilterBestTime
              handleFilters={(arg) => handleFilters(arg)}
            />
          </Drawer>
        )}
        <Col md={16} xs={24} lg={18} xxl={20}>
          <div className={classes.content} style={{ padding: md ? 16 : 0 }}>
            <Spin spinning={loadingBesttime} size="large">
              <Row>
                { activity && categories.map((key) => (
                  <Col key={key} xs={24} md={24} lg={12} xxl={8} className={classes.chartCol}>
                    <ChartBesttimeLine data={activity.result[key]} color={Res.colorBestTime} color_alpha={Res.colorBestTime_alpha} label={key.charAt(0).toUpperCase() + key.slice(1)} labels={activity.key} />
                  </Col>
                ))}
              </Row>
            </Spin>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default BestTimePage;
