import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'redux/store';
import { PATHS } from 'res/routes';
import history from 'redux/history';

// layout
import ContentLayout from 'layouts/ContentLayout';
import AuthLayout from 'layouts/AuthLayout';

// pages
import Image from 'pages/imagePage';
import Trash from 'pages/trashPage';

import CameraPage from 'pages/cameraPage';
import CameraAddPage from 'pages/cameraAddPage';
import CameraEdit from 'pages/cameraEdit';
import CameraEditPin from 'pages/cameraEditPin';
import CameraAddPin from 'pages/cameraAddPin';
import CameraRemotePage from 'pages/cameraRemotePage';

import ActivityPage from 'pages/activityPage';
import ProfilePage from 'pages/profilePage';
import AuthSignInPage from 'pages/authSiginPage';
import AuthResetPassword from 'pages/authResetPassword';
import AuthResetEmailSent from 'pages/authResetEmailSent';
import AuthEnterPassword from 'pages/authEnterPassword';
import ImageUpload from 'pages/imageUpload';
import Dashboard from 'pages/dashboardPage';

import PrivateRoute from 'layouts/PrivateRoute';
import BestTimePage from 'pages/bestTimePage';
import CameraGroup from 'pages/cameraGroupPage';
import ImageTagPage from 'pages/imageTagPage';
import SubCameraPage from 'pages/SubCameraPage';
import SubImagePage from 'pages/SubImagePage';
import SubActivityPage from 'pages/SubActivityPage';
import AuthDeveloper from 'pages/authDeveloper';
import WeatherPage from 'pages/weatherPage';
import PredictionPage from 'pages/PredictionPage';
import DataUsagePage from 'pages/dataUsagePage';
import AuthSignInTokenPage from 'pages/authSiginTokenPage';
import MovementMapPage from 'pages/movementMapPage';

import 'App.less';

function App() {
  const renderWithLayout = (Component, Layout) => <Layout>{Component}</Layout>;
  return (
    <div className="App">
      <Provider store={store}>
        <div className="App">
          <Router history={history}>
            <div>
              <Switch>
                <PrivateRoute
                  path={PATHS.HOME}
                  exact
                  render={() => renderWithLayout(<Dashboard />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.IMAGE}
                  exact
                  render={() => renderWithLayout(<Image />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.IMAGE_TAG}
                  exact
                  render={() => renderWithLayout(<ImageTagPage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.TRASH}
                  exact
                  render={() => renderWithLayout(<Trash />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.CAMERA}
                  exact
                  render={() => renderWithLayout(<CameraPage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.CAMERA_GROUP}
                  exact
                  render={() => renderWithLayout(<CameraGroup />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.CAMERA_ADD}
                  exact
                  render={() => renderWithLayout(<CameraAddPage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.CAMERA_EDIT}
                  exact
                  render={() => renderWithLayout(<CameraEdit />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.CAMERA_REMOTE}
                  exact
                  render={() => renderWithLayout(<CameraRemotePage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.CAMERA_EDIT_PIN}
                  exact
                  render={() => renderWithLayout(<CameraEditPin />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.CAMERA_ADD_PIN}
                  exact
                  render={() => renderWithLayout(<CameraAddPin />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.SUB_CAMERA}
                  exact
                  render={() => renderWithLayout(<SubCameraPage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.SUB_IMAGE}
                  exact
                  render={() => renderWithLayout(<SubImagePage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.SUB_ACTIVITY}
                  exact
                  render={() => renderWithLayout(<SubActivityPage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.ACTIVITY}
                  exact
                  render={() => renderWithLayout(<ActivityPage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.BESTTIME}
                  exact
                  render={() => renderWithLayout(<BestTimePage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.PREDICTION}
                  exact
                  render={() => renderWithLayout(<PredictionPage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.WEATHER}
                  exact
                  render={() => renderWithLayout(<WeatherPage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.PROFILE}
                  exact
                  render={() => renderWithLayout(<ProfilePage />, ContentLayout)}
                />c
                <PrivateRoute
                  path={PATHS.IMAGE_UPLOAD}
                  exact
                  render={() => renderWithLayout(<ImageUpload />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.DATA_USAGE}
                  exact
                  render={() => renderWithLayout(<DataUsagePage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.MOVEMENT_MAP}
                  exact
                  render={() => renderWithLayout(<MovementMapPage />, ContentLayout)}
                />
                <Route
                  path={PATHS.SIGNIN}
                  exact
                  render={() => renderWithLayout(<AuthSignInPage />, AuthLayout)}
                />
                <Route
                  path={PATHS.SIGNIN_WITH_TOKEN}
                  exact
                  render={() => renderWithLayout(<AuthSignInTokenPage />, AuthLayout)}
                />
                <Route
                  path={PATHS.AUTH_DEVELOPER}
                  exact
                  render={() => renderWithLayout(<AuthDeveloper />, AuthLayout)}
                />
                <Route
                  path={PATHS.AUTH_RESET_PASSWORD}
                  exact
                  render={() => renderWithLayout(<AuthResetPassword />, AuthLayout)}
                />
                <Route
                  path={PATHS.AUTH_ENTER_PASSWORD}
                  exact
                  render={() => renderWithLayout(<AuthEnterPassword />, AuthLayout)}
                />
                <Route
                  path={PATHS.AUTH_RESET_EMAIL_SENT}
                  exact
                  render={() => renderWithLayout(<AuthResetEmailSent />, AuthLayout)}
                />
              </Switch>
            </div>
          </Router>
        </div>
      </Provider>
    </div>
  );
}

export default App;
