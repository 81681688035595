import React, { useEffect, useState } from 'react';
import * as Api from 'api';
import useAuth from 'redux/auth/auth.hook';
import { Row, Col } from 'antd';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import ProgressBar from 'components/progressbar';

import classes from './style.module.scss';

am4core.useTheme(am4themes_animated);

const DatausageTopCameras = () => {
  const { auth: { profile } } = useAuth();
  const [topCameras, setTopCameras] = useState<any[]>([]);

  useEffect(() => {
    if (profile) {
      Api.DATA_USAGE_TOP_CAMERAS().then((res1 : any) => {
        const cameras = JSON.parse(res1.text);
        setTopCameras(cameras);
      });
    }
  }, [profile]);

  const checkDataUnit = (size: number) => {
    if (size == -1) {
      return 'Unlimited';
    }
    let dataString = '0B';
    if (size > 1000000000) {
      const value = (size / 1000000000).toFixed(1);
      dataString = value.concat('GB');
    } else if (size > 1000000) {
      const value = (size / 1000000).toFixed(1);
      dataString = value.concat('MB');
    } else if (size > 1000) {
      const value = (size / 1000).toFixed(1);
      dataString = value.concat('KB');
    } else if (size > 1) {
      const value = size.toFixed(1);
      dataString = value.concat('B');
    }
    return dataString;
  };

  return (
    <Row align="middle">
      <Col md={24} style={{ padding: 8 }}>
        {topCameras.map((camera) => (
          <div key={camera.name} style={{ margin: '10px 20px 10px 20px' }}>
            <span>{camera.name}</span>
            <ProgressBar isLoading={false} percent={(camera.data_usage * 100) / topCameras[0].data_usage} size="large" showInfo info={checkDataUnit(camera.data_usage)} />
          </div>
        ))}
      </Col>
    </Row>
  );
};

export default DatausageTopCameras;
