import { faBan, faTrashRestore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, Col, Modal, notification, Row, Spin } from 'antd';
import * as Api from 'api';
import ImageCard from 'components/imageCard';
import MediaBox from 'components/MediaBox';
import { ImageType } from 'helpers/types';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import useAuth from 'redux/auth/auth.hook';
import classes from './style.module.scss';

const TrashPage = () => {
  const { auth: { profile } } = useAuth();
  const [loading, setLoading] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [activeCardIndex, setActiveCardIndex] = useState(-1);
  const [images, setImages] = useState<ImageType[]>([]);

  useEffect(() => {
    if (profile) {
      httpLoadData(0, false);
    }
  }, [profile]);

  const httpLoadData = (start, more) => {
    setLoading(true);
    Api.IMAGE_LOAD_TRASH({ user_id: profile.user_id, start, length: 36 }).then(((res: any) => {
      if (more) {
        setImages(images.concat(JSON.parse(res.text).data));
      } else {
        setImages(JSON.parse(res.text).data);
      }
      setLoading(false);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
    setStartIndex(start);
  };

  const onScroll = () => {
    httpLoadData(startIndex + 36, true);
  };

  const selectCard = (index, value) => {
    const newArr = [...images];
    newArr[index].selected = value;
    setImages(newArr);
  };

  const hideModal = () => {
    setActiveCardIndex(-1);
  };

  const reloadPage = () => {
    setImages([]);
    httpLoadData(0, false);
  };
  // Event
  const onRestore = () => {
    const ids: any = [];

    images.map((e) => {
      if (e.selected) {
        ids.push(e.id);
      }
      return null;
    });

    console.log('ids', ids);

    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
    } else {
      const params = {
        ids,
      };

      Api.IMAGE_RESTORE(params).then((() => {
        reloadPage();
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
    }
  };

  const onClear = () => {
    console.log('http onClear');
    const ids: any = [];

    images.map((e) => {
      if (e.selected) {
        ids.push(e.id);
      }
      return null;
    });

    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
    } else {
      const params = {
        ids,
      };

      Api.IMAGE_DELETE(params).then((() => {
        reloadPage();
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
    }
  };

  const onChangeCheckBox = (e) => {
    const newArr = [...images];
    newArr.map((image) => {
      image.selected = e.target.checked;
      return null;
    });
    setImages(newArr);
  };

  return (
    <>
      <Row>
        <Row className={classes.containerFloat}>
          <Col span="6" className={classes.divLeft}>
            <Button type="primary" shape="round" size="large">
              <Checkbox className={classes.checkBox} onChange={onChangeCheckBox} />
              &nbsp;
              Select All
            </Button>
          </Col>
          <Col span="6" offset="12">
            <div className={classes.divRight}>
              <Button type="primary" shape="round" size="large" icon={<FontAwesomeIcon icon={faTrashRestore} />} onClick={onRestore}>
                &nbsp;
                Restore
              </Button>
              <Button type="primary" shape="round" size="large" icon={<FontAwesomeIcon icon={faBan} />} onClick={onClear}>
                &nbsp;
                Clear
              </Button>
            </div>
          </Col>
        </Row>
        <Spin spinning={loading} size="large" wrapperClassName={classes.content}>
          <InfiniteScroll
            dataLength={images.length}
            next={onScroll}
            hasMore
            loader=""
            endMessage={
              (
                <p style={{ textAlign: 'center' }}>
                  <b>No more images</b>
                </p>
              )
            }
          >
            <Row className={classes.content}>
              {images.map((e, i) => (
                <Col xs={12} md={6} lg={6} xl={4} key={i}>
                  <ImageCard key={i} index={i} detail={e} onClick={() => { setActiveCardIndex(i); }} onSelect={(arg) => { selectCard(i, arg); }} onFavorite={(index, isFavorite) => {}} />
                </Col>
              ))}
            </Row>
          </InfiniteScroll>
        </Spin>
      </Row>
      <Modal
        visible={activeCardIndex > -1}
        onOk={hideModal}
        onCancel={hideModal}
        width={1000}
        className={`${classes.mediaModal} image-gallery-modal`}
        bodyStyle={{ marginTop: 32, backgroundColor: 'transparent', padding: 0 }}
        footer={null}
      >
        {activeCardIndex > -1 && (
          <MediaBox
            images={images}
            activeIndex={activeCardIndex}
            onIndexChange={(index) => setActiveCardIndex(index)}
          />
        )}
      </Modal>
    </>
  );
};

export default TrashPage;
