import { Button, Form, Grid, Input, notification } from 'antd';
import React, { useEffect } from 'react';
import useAuth from 'redux/auth/auth.hook';
import history from 'redux/history';
import { PATHS } from 'res/routes';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

const SignForm = () => {
  const { signRequestSaga, auth } = useAuth();
  const { md } = useBreakpoint();

  console.log('auth ==>', auth);

  const onFinish = (values: any) => {
    console.log('onFinish ====>', values);
    signRequestSaga(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    if (auth.profile) {
      if (auth.profile.visited_at == '2019-07-26 22:42:53') {
        history.push(PATHS.CAMERA);
      } else {
        history.push(PATHS.HOME);
      }
    }
  }, [auth.profile]);

  useEffect(() => {
    if (auth.message) {
      if (auth.message != '') {
        notification.open({
          message: auth.message,
        });
      }
    }
  }, [auth.message]);

  return (
    <Form initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <div className={classes.formWrapper} style={{ width: md ? 500 : 300 }}>
        <h4>Sign in to your account</h4>

        <div className={classes.formItemLabel}>Email/Username</div>
        <Form.Item name="username" rules={[{ required: true, message: 'Please input your email!' }]}>
          <Input size="large" />
        </Form.Item>

        <div className={classes.formItemLabel}>Password</div>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password size="large" />
        </Form.Item>

        <Form.Item>
          <Button className={classes.btnSignin} htmlType="submit" size="large">
            Sign In
          </Button>
        </Form.Item>

        <div className={classes.signupFormHeader}>
          <a href="/authResetPassword">Forgot your password?</a>
        </div>
      </div>
    </Form>
  );
};

export default SignForm;
