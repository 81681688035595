import React from 'react';
import { Row, Col, Grid, Card } from 'antd';
import DatausageForecast from 'components/DatausageForecast';
import DatausageTopCameras from 'components/DatausageTopCameras';
import DatausageSubscription from 'components/DatausageSubscription';

import classes from './style.module.scss';

const { useBreakpoint } = Grid;

const DataUsagePage = () => {
  const { md } = useBreakpoint();
  return (
    <Row>
      <Col md={24} xs={24}>
        <div className={classes.content} style={{ padding: 0 }}>
          <Row>
            <Col md={12} xs={24} style={{ padding: 8 }}>
              <Card title="My Subscription" bodyStyle={{ padding: '8px' }} style={{ marginTop: '20px' }}>
                <DatausageSubscription />
              </Card>
              <Card title="Usage And Forecast" bodyStyle={{ padding: '8px' }} style={{ marginTop: '20px' }}>
                <DatausageForecast />
              </Card>
            </Col>
            <Col md={12} xs={24} style={{ padding: 8 }}>
              <Card title="Top 5 Cameras" bodyStyle={{ padding: '8px' }} style={{ marginTop: '20px' }}>
                <DatausageTopCameras />
              </Card>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default DataUsagePage;
