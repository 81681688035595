import { FileImageOutlined } from '@ant-design/icons';
import { margin } from '@mui/system';
import { Button, Card, Carousel, Col, Form, Grid, Input, InputNumber, notification, Popconfirm, Row, Spin, Switch } from 'antd';
import * as Api from 'api';
import CameraAddMap from 'components/cameraAddMap';
import DashboardImageCard from 'components/dashboardImageCard';
import DashboardRecently from 'components/dashboardRecently';
import { CameraPinType } from 'helpers/types';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useAuth from 'redux/auth/auth.hook';
import { PATHS } from 'res/routes';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

const buttonLayout = {
  wrapperCol: {
    span: 24,
  },
};

interface ParamTypes {
  camera_id: string
}
const CameraEdit = () => {
  const { camera_id } = useParams<ParamTypes>();

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const { md } = useBreakpoint();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingRecently, setLoadingRecently] = useState(false);

  const { auth: { profile } } = useAuth();
  const [camera, setCamera] = useState<any>({
    id: 0,
    name: '',
    IMEI: '',
    IMEI_suspended: '',
    latitude: 30.684755,
    longitude: -90.969077,
    type: 'Camera',
    fix_location: 0,
    is_active: 0,
  });
  const [images, setImages] = useState<any>([]);

  useEffect(() => {
    if (camera_id) {
      loadData();
      loadRecentlyImages();
    }
  }, [camera_id]);

  const loadData = () => {
    setLoading(true);
    Api.CAMERA_GET_ONE(camera_id).then(((res: any) => {
      const result = JSON.parse(res.text);
      console.log(result);
      setCamera(result.camera);
      form.setFieldsValue(result.camera);
      setLoading(false);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const loadRecentlyImages = () => {
    if (profile) {
      // get cameras and tags
      setLoadingRecently(true);
      Api.CAMERA_RECENTLY_IMAGES(camera_id).then((res: any) => {
        const result = JSON.parse(res.text);
        console.log(result);
        setImages(result.images);
        setLoadingRecently(false);
      });
    }
  };

  const onValuesChange = (values, allvalues) => {
    console.log(allvalues);
    const newCamera = { ...camera, ...allvalues };
    setCamera(newCamera);
  };

  const onFinish = (values) => {
    console.log('Success2:', values);
    const params = {
      user_id: profile.user_id,
      name: camera.name,
      id: camera.id,
      latitude: camera.latitude,
      longitude: camera.longitude,
      IMEI: camera.IMEI,
      fix_location: camera.fix_location,
    };
    Api.CAMERA_EDIT(params).then(((res: any) => {
      const data = JSON.parse(res.text);
      if (data.result == 'ok') {
        const path = PATHS.CAMERA;
        history.push(path);
      } else {
        notification.open({
          message: data.msg,
        });
      }
      // setForecastdata(data.data);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onFinishAssign = (values) => {
    const params = {
      IMEI: values.IMEI,
      camera_id: camera.id,
    };
    Api.CAMERA_ASSIGN(params).then(((res: any) => {
      const data = JSON.parse(res.text);
      if (data.result == 'OK') {
        const path = PATHS.CAMERA;
        history.push(path);
      } else {
        notification.open({
          message: data.message,
        });
      }
      // setForecastdata(data.data);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onDragEnd = (evt) => {
    const { latLng } = evt;
    // updateMarkerPositionTxt(latLng);

    const newCamera = {
      ...camera,
      ...{
        latitude: Number(latLng.lat().toFixed(6)),
        longitude: Number(latLng.lng().toFixed(6)),
      },
    };
    setCamera(newCamera);
    form.setFieldsValue(newCamera);
  };

  const deleteItem = () => {
    if (window.confirm('Are you sure you want to delete this camera?')) {
      Api.CAMERA_DELETE(camera_id).then((res) => {
        console.log('delete camera', res);
        history.goBack();
      }).catch((error: any) => {
        console.log('error ===>', error);
      });
    }
  };

  const onChangeFixLocation = (checked) => {
    console.log(`switch to ${checked}`);
    const newCamera = {
      ...camera,
      ...{
        fix_location: checked ? 1 : 0,
      },
    };
    setCamera(newCamera);
    form.setFieldsValue(newCamera);
  };
  const onTrashAll = () => {
    const params = {
      camera_id: camera.id,
    };
    Api.CAMERA_TRASH_ALL(params).then(((res: any) => {
      notification.open({
        message: res.text,
      });
      loadRecentlyImages();
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onArchive = () => {
    const params = {
      camera_id: camera.id,
    };
    Api.CAMERA_ARCHIVE(params).then(((res: any) => {
      const data = JSON.parse(res.text);
      if (data.result == 'OK') {
        const path = PATHS.CAMERA;
        history.push(path);
      } else {
        notification.open({
          message: data.message,
        });
      }
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onChangeActivate = (checked) => {
    console.log('error ===>', checked);
    const params = {
      IMEI: camera.IMEI,
      is_active: checked ? 1 : 0,
    };
    setLoading(true);
    Api.CAMERA_ACTIVATE(params).then(((res: any) => {
      setLoading(false);
      const data = JSON.parse(res.text);
      if (data.result == 'OK') {
        loadData();
      } else {
        notification.open({
          message: data.message,
        });
      }
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  return (
    <>
      <Row>
        {md && (
          <Col md={16} className={classes.leftMap} style={{ height: '80vh' }}>
            <CameraAddMap camera={camera} onDragEnd={onDragEnd} />
          </Col>
        )}
        <Col md={8} xs={24}>
          <div className={classes.content} style={{ padding: md ? 24 : 16 }}>
            <Spin spinning={loading} size="large">
              <Card title="Camera" bodyStyle={{ padding: '8px' }}>
                <Form
                  {...layout}
                  form={form}
                  name="basic"
                  onValuesChange={onValuesChange}
                  onFinish={onFinish}
                >
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input camera name',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Latitude"
                    name="latitude"
                    rules={[
                      {
                        required: true,
                        message: 'Latitude',
                      },
                    ]}
                  >
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item
                    label="Longitude"
                    name="longitude"
                    rules={[
                      {
                        required: true,
                        message: 'Longitude',
                      },
                    ]}
                  >
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item
                    label="Fixed Location"
                    name="fix_location"
                  >
                    <Switch checked={camera.fix_location == 1} onChange={onChangeFixLocation} />
                  </Form.Item>
                  <Row>
                    <Col offset={6}>
                      <Form.Item {...buttonLayout}>
                        <Button type="ghost" onClick={deleteItem}>
                          Delete
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col offset={1} span={6}>
                      <Form.Item {...buttonLayout}>
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>

                  {
                    camera.IMEI != '' && (
                      <>
                      </>
                    )
                  }
                </Form>
              </Card>
            </Spin>
            {
              camera.IMEI != '' && (
                <Card
                  title="Activate"
                  bodyStyle={{ padding: '8px' }}
                  style={{ marginTop: '20px' }}
                >
                  <Form
                    {...layout}
                    name="basic"
                  >
                    <p style={{ margin: '16px' }} className="text-muted">Activating/Deactivating your cameras does not automatically remove them from your subscription. Please visit <a href="https://development.huntcontrol.com/my-account/">My Account</a> if you wish to increase/reduce the number of cams in your subscription.</p>
                    <Form.Item
                      label="Activate"
                      name="activate"
                    >
                      <Switch checked={camera.is_active == 1} onChange={onChangeActivate} />
                    </Form.Item>
                  </Form>
                </Card>
              )
            }
            {
              camera.IMEI != '' && (
                <Card
                  title="Archive"
                  bodyStyle={{ padding: '8px' }}
                  style={{ marginTop: '20px' }}
                  extra={(
                    <Popconfirm title="Are you sure to archive this camera?" onConfirm={onArchive}>
                      <Button type="ghost" className={classes.editTrash}>
                        Archive
                      </Button>
                    </Popconfirm>
                  )}
                >
                  <Form
                    {...layout}
                    name="basic"
                  >
                    <p style={{ margin: '16px' }} className="text-muted">By Clicking the Archive button you will freeze the data and images for this location and be allowed to use the IMEI number in a new location. This is useful if you want to move the camera to a new site and don’t want the old images and data to be affected by the move.</p>
                  </Form>
                </Card>
              )
            }
            {
              camera.IMEI == '' && (
                <Card
                  title="Assign"
                  bodyStyle={{ padding: '8px' }}
                  style={{ marginTop: '20px' }}
                >
                  <Form
                    {...layout}
                    form={form2}
                    name="basic"
                    onFinish={onFinishAssign}
                  >
                    {
                      camera.IMEI_suspended != '' && (
                        <Form.Item
                          label="Old IMEI"
                          rules={[
                            {
                              required: true,
                              message: 'Please input IMEI',
                            },
                          ]}
                        >
                          <Input value={camera.IMEI_suspended} disabled />
                        </Form.Item>
                      )
                    }
                    <Form.Item
                      label="IMEI"
                      name="IMEI"
                      rules={[
                        {
                          required: true,
                          message: 'Please input IMEI',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Row>
                      <Col offset={6}>
                        <Form.Item {...buttonLayout}>
                          <Button type="primary" htmlType="submit">
                            Assign
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Card>
              )
            }
            <Card
              title="Recently Uploaded Images"
              extra={(
                <Popconfirm title="Are you sure to move all images of this camera to the trash folder?" onConfirm={onTrashAll}>
                  <Button type="ghost" className={classes.editTrash}>
                    Trash All
                  </Button>
                </Popconfirm>
              )}
              bodyStyle={{ padding: '8px' }}
              style={{ marginTop: '20px' }}
            >
              <Spin spinning={loadingRecently} size="large">
                <Carousel
                  autoplay
                  dotPosition="top"
                >
                  {images.map((e, i) => (
                    <DashboardImageCard key={i} detail={e} />
                  ))}
                </Carousel>
              </Spin>
            </Card>
            {!md && (
              <Col xs={24} md={24} style={{ height: '400px' }}>
                <CameraAddMap camera={camera} onDragEnd={onDragEnd} />
              </Col>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CameraEdit;
