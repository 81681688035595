import { Col, Grid, notification, Row, Select, Space, Spin, Tag } from 'antd';
import * as Api from 'api';
import CameraRemoteBig from 'components/cameraRemoteBig';
import CameraRemoteMini from 'components/cameraRemoteMini';
import { CameraPinType } from 'helpers/types';
import React, { useEffect, useState } from 'react';
import useAuth from 'redux/auth/auth.hook';
import CameraRemoteMiniV2 from 'components/cameraRemoteMiniV2';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

const { Option } = Select;
const CameraRemotePage = () => {
  const { md } = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [cameras, setCameras] = useState<CameraPinType[]>([]);
  const [selected, setSelected] = useState(0);

  const { auth: { profile } } = useAuth();
  const [camera, setCamera] = useState<any>(null);
  const [device, setDevice] = useState<any>(null);

  useEffect(() => {
    if (profile) {
      httpGetCameras();
    }
  }, [profile]);

  const changeCamera = (value) => {
    console.log(`selected ${value}`);
    setSelected(value);
    setCamera(cameras[value]);
    httpGetInfo(cameras[value].IMEI);
  };

  const httpGetCameras = () => {
    setLoading(true);
    Api.CAMERA_GET_LIVE_ALL().then(((res: any) => {
      setLoading(false);
      const result = JSON.parse(res.text);
      setCameras(result.data);
      if (result.data.length > 0) {
        setCamera(result.data[0]);
        httpGetInfo(result.data[0].IMEI);
      } else {
        notification.open({
          message: 'You don\'t have any activated smart cams now',
        });
      }
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const httpGetInfo = (IMEI) => {
    setLoading(true);
    const params = {
      IMEI,
    };
    Api.CAMERA_GET_LIVE_INFO(params).then(((res: any) => {
      setLoading(false);
      const result = JSON.parse(res.text);
      if (result.data == null) {
        notification.open({
          message: 'Invalue IMEI',
        });
      } else {
        if (result.data.version == 'B' && result.data.pir_sensitivity == 0) {
          result.data.pir_sensitivity = 5;
        }
        setDevice(result.data);
      }
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };
  return (
    <>
      <Row>
        <Col xs={24}>
          <div className={classes.content} style={{ padding: md ? 24 : 16 }}>
            <Spin spinning={loading} size="large">
              <div className={classes.divCamera}>
                <Space>
                  <div className={classes.formInputLabel}>Camera:&nbsp;</div>
                  <Select
                    size="large"
                    className={classes.select}
                    onChange={changeCamera}
                    value={selected}
                  >
                    {cameras.map((val: any, i) => (
                      <Option key={val.id} value={i}>{val.name}</Option>
                    ))}
                  </Select>
                  <Tag>
                    {device != null && (device.version == 'A' ? 'Standard' : (device.version == 'B' ? 'Mini' : 'DataCam'))}
                  </Tag>
                </Space>
              </div>
              {device != null && camera != null && device.version == 'A' && (<CameraRemoteBig device={device} camera={camera} />)}
              {device != null && camera != null && device.version != 'A' && (device.is_after_d404 ? <CameraRemoteMiniV2 device={device} camera={camera} isLastFirmware={device.is_last_firmware} /> : <CameraRemoteMini device={device} camera={camera} isLastFirmware={device.is_last_firmware} />)}
            </Spin>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CameraRemotePage;
