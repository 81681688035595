import { LogoutOutlined, QuestionCircleOutlined, YoutubeOutlined } from '@ant-design/icons';
import type { ProSettings } from '@ant-design/pro-layout';
import ProLayout, { PageContainer } from '@ant-design/pro-layout';
import { Alert, Grid, notification } from 'antd';
import defaultProps from 'config/menuProps';
import defaultPropsMain from 'config/menuPropsMain';
import React, { ReactElement, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import history from 'redux/history';
import useAuth from 'redux/auth/auth.hook';
import logo from '../../assets/image/hunt_control_logo.png';

interface Props {
  children: ReactElement;
}

const { useBreakpoint } = Grid;

function ContentLayout({ children }: Props) {
  const [settings] = useState<Partial<ProSettings> | undefined>({ fixSiderbar: true });
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);
  const { md, xs } = useBreakpoint();
  const { auth: { profile } } = useAuth();

  const logout = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  const onPreviousVersion = () => {
    let url = 'https://www.youtube.com/watch?v=loQ_8apONMQ&list=PLeySVcHqd3hEhViBVpMSxVLc-fovs37Rl&index=2';
    if (pathname == '/') {
      url = 'https://www.youtube.com/watch?v=loQ_8apONMQ&list=PLeySVcHqd3hEhViBVpMSxVLc-fovs37Rl&index=2';
    } else if (pathname == '/image/all') {
      url = 'https://www.youtube.com/watch?v=7kfP46xZNOk&list=PLeySVcHqd3hEhViBVpMSxVLc-fovs37Rl&index=3';
    } else if (pathname == '/imageTag') {
      url = 'https://www.youtube.com/watch?v=EtmJ7LHNxsk&list=PLeySVcHqd3hEhViBVpMSxVLc-fovs37Rl&index=4';
    } else if (pathname == '/camera') {
      url = 'https://www.youtube.com/watch?v=sXJAwMAppgg&list=PLeySVcHqd3hEhViBVpMSxVLc-fovs37Rl&index=9';
    } else if (pathname == '/cameraRemote') {
      url = 'https://www.youtube.com/watch?v=fdR4GK38XKM&list=PLeySVcHqd3hEhViBVpMSxVLc-fovs37Rl&index=5';
    } else if (pathname == '/cameraGroup') {
      url = 'https://www.youtube.com/watch?v=rqY4cNHOKBM&list=PLeySVcHqd3hEhViBVpMSxVLc-fovs37Rl&index=7';
    } else if (pathname == '/activity') {
      url = 'https://www.youtube.com/watch?v=bYOa3r0Hb7g&list=PLeySVcHqd3hEhViBVpMSxVLc-fovs37Rl&index=8';
    } else if (pathname == '/besttime') {
      url = 'https://www.youtube.com/watch?v=tkoBK6zH9jY&list=PLeySVcHqd3hEhViBVpMSxVLc-fovs37Rl&index=10';
    } else if (pathname == '/forecast') {
      url = 'https://www.youtube.com/watch?v=y4FVLi5Q_zA&list=PLeySVcHqd3hEhViBVpMSxVLc-fovs37Rl&index=11';
    } else if (pathname == '/profile') {
      url = 'https://www.youtube.com/watch?v=S78cji7Zkxg&list=PLeySVcHqd3hEhViBVpMSxVLc-fovs37Rl&index=6';
    }
    // const win = window.open(encodeURI(url), '_blank');
    const windowReference = window.open();

    if (windowReference) {
      windowReference.location = url;
      windowReference.focus();
    } else {
      notification.open({
        message: 'Alert',
        description:
          'Please allow popups for this website.',
      });
    }
  };

  useEffect(() => {
    if (location) {
      if (pathname !== location.pathname) {
        if (location.pathname.includes('/image/')) {
          setPathname('/image/all');
        } else if (location.pathname.includes('/sub_image/')) {
          setPathname('/sub_image/all');
        } else {
          setPathname(location.pathname);
        }
      }
    }
  }, [location]);

  return (
    <ProLayout
      {...profile.is_main == 1 ? defaultPropsMain : defaultProps}
      logo={logo}
      title="HuntControl"
      fixedHeader
      location={{
        pathname,
      }}
      {...settings}
      menuHeaderRender={(_logo, _title) => (
        <div id="customize_menu_header">
          {_logo}
          {_title}
        </div>
      )}
      onMenuHeaderClick={() => history.push('/')}
      menuItemRender={(menuItemProps, defaultDom) => {
        if (menuItemProps.isUrl || !menuItemProps.path) {
          return defaultDom;
        }
        return <Link to={menuItemProps.path} onClick={() => setPathname(menuItemProps.path ?? '/')}>{defaultDom}</Link>;
      }}
      rightContentRender={() => (
        <>
          <a href="" onClick={onPreviousVersion} style={{ cursor: 'pointer', flex: md ? '100' : '0.2' }}>
            <QuestionCircleOutlined />&nbsp;
            {md ? 'Youtube Tutorial' : ''}
          </a>
          <span onClick={logout} style={{ cursor: 'pointer' }}>
            <LogoutOutlined />
            Logout
          </span>
        </>
      )}
    >
      <PageContainer ghost>
        {children}
      </PageContainer>
    </ProLayout>
  );
}

export default ContentLayout;
