/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Card, Tag, Tooltip } from 'antd';
import moment from 'moment';
import { CameraPinDetailType, CameraPinType } from 'helpers/types';
import { Battery0Bar, Battery1Bar, Battery2Bar, Battery3Bar, Battery4Bar, Battery5Bar, Battery6Bar, BatteryFull, SignalCellular0Bar, SignalCellular1Bar, SignalCellular2Bar, SignalCellular3Bar, SignalCellular4Bar } from '@mui/icons-material';
import React from 'react';
import { Link } from 'react-router-dom';
// import { WarningOutlined } from '@ant-design/icons';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SignalComponent from 'components/cameraSimbols/SignalComponent';
import BatteryComponent from 'components/cameraSimbols/BatteryComponent';
import AttComponent from 'components/cameraSimbols/AttComponent';
import TypeComponent from 'components/cameraSimbols/TypeComponent';
import classes from './style.module.scss';

interface props {
  detail: CameraPinDetailType,
}

// function TypeComponent(props) {
//   const { detail } = props;
//   if (detail.version == 'A') {
//     return <div className={classes.labelType}>Standard</div>;
//   }
//   return <div className={classes.labelType}>Mini</div>;
// }

// function AttComponent(props) {
//   const { detail } = props;
//   if (detail.att_verizon == 'ATT') {
//     return <img className={classes.image} alt="test" src="/icons_camera/carrier_logo_att.png" />;
//   }
//   return <img className={classes.image} alt="test" src="/icons_camera/carrier_logo_verizon.png" />;
// }

const CameraCard: React.FC<props> = ({ detail }) => (
  <div style={{ padding: 4 }}>
    <Card
      hoverable
      bodyStyle={{ padding: 8 }}
    >
      <Link to={detail.type == '' || detail.type == null ? `/cameraEdit/${detail.id}` : `/cameraEditPin/${detail.id}`}>
        <div className={classes.divBody}>
          <img className={classes.image} alt="test" src={`/map_pins/${detail.type ? detail.type : 'Camera'}.png`} />
          <div className={classes.divLeft}>
            <div className={classes.divBody}>
              <div className={classes.labelName}>{detail.name} </div>&nbsp;&nbsp;
              <div className={classes.labelIMEI}>{detail.IMEI}</div>
              {
                Math.abs(detail.latitude) < 2 && Math.abs(detail.longitude) < 2 && (
                  <Tooltip title="The location information for this camera seems to be incorrect. Update the location to get the correct weather information based on the camera's location.">
                    <ErrorOutlineIcon />
                  </Tooltip>
                )
              }
            </div>
            {detail.IMEI && <div className={classes.labelDate}>Last Activity: {detail.last_image_taken_at > '0001' ? moment(detail.last_image_taken_at).format('hh:mm A - MMM D, Y') : ''}</div>}
          </div>
          {
            (detail.type == '' || detail.type == null) && detail.IMEI != '' && detail.is_active == 0 && (
              <div className={classes.divActivate}>
                Suspended
              </div>
            )
          }
          <div className={classes.divCarrier}>
            {detail.IMEI && <AttComponent detail={detail} />}
            {detail.IMEI && <TypeComponent detail={detail} />}
          </div>
          <div className={classes.divRight}>
            {detail.IMEI && <BatteryComponent detail={detail} />}
          </div>
          <div className={classes.iconSignal}>
            {detail.IMEI && <SignalComponent detail={detail} />}

          </div>
        </div>
      </Link>
    </Card>
  </div>
);

export default CameraCard;
