import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Select, TimePicker, Card, notification, Input, Switch, Space, Tooltip, Popconfirm, Divider, Collapse, Modal, Alert } from 'antd';
import moment from 'moment';
import * as Api from 'api';
import useAuth from 'redux/auth/auth.hook';
import AttComponent from 'components/cameraSimbols/AttComponent';
import BatteryComponent from 'components/cameraSimbols/BatteryComponent';
import SignalComponent from 'components/cameraSimbols/SignalComponent';
import CameraVersion from 'components/cameraSimbols/CameraVersion';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { QuestionCircleOutlined } from '@ant-design/icons';
import classes from './style.module.scss';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const format = 'HH:mm';

const optionsCameraMode = [
  { value: 1, label: 'Photo (default)' },
  { value: 2, label: 'Video' },
  { value: 3, label: 'PIC+Video' },
];

const optionsPictureSize = [
  { value: 3, label: '5M (default)' },
  { value: 2, label: '8M' },
  { value: 1, label: '12M' },
];

const optionsPirSensitivity = [
  { value: 1, label: '1 (Lowest)' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7 (Default)' },
  { value: 8, label: '8' },
  { value: 9, label: '9 (Highest)' },
];

const optionsCellular = [
  { value: 0, label: '4 Times Daily (default)' },
  { value: 1, label: 'Always Available' },
];

const optionsMultiShot = [
  { value: 1, label: '1 Picture (default)' },
  { value: 2, label: '2 Pictures' },
  { value: 3, label: '3 Pictures' },
];

const optionsNightMode = [
  { value: 1, label: 'Max.Range' },
  { value: 2, label: 'Balanced (default)' },
  { value: 3, label: 'Min.Blur' },
];

const optionsSendingMode = [
  { value: 0, label: 'Instant (default)' },
  { value: 1, label: 'Every 1H' },
  { value: 2, label: 'Every 4H' },
];

const optionsIRFlash = [
  { value: 0, label: 'Far (default)' },
  { value: 1, label: 'Near' },
];

const optionsVideoSending = [
  { value: 0, label: 'Don’t Send Video', disabled: true },
  { value: 1, label: 'Thumbnail Image' },
  { value: 2, label: 'Full Video (default)' },
];

const optionsVideoSize = [
  { value: 1, label: '1080P' },
  { value: 2, label: '720P' },
  { value: 3, label: 'WVGA (default)' },
];

const optionsVideoLength = [
  { value: 5, label: '5  (default)' },
  { value: 10, label: '10' },
  { value: 15, label: '15' },
];

const optionsVideoLength1080 = [
  { value: 5, label: '5  (default)' },
  { value: 10, label: '10' },
];

const CameraRemoteMiniV2 = (props) => {
  const [form] = Form.useForm();
  const { md } = useBreakpoint();
  const [switchName, setSwitchName] = useState(true);
  const { auth: { profile } } = useAuth();
  const [switchTimeLapse, setSwitchTimeLapse] = useState(true);
  const [isLastFirmware, setIsLastFirmware] = useState(true);
  const [workTimer1, setWorkTimer1] = useState(true);
  const [workTimer2, setWorkTimer2] = useState(true);
  const [cameraMode, setCameraMode] = useState<any>(1);
  const [videoQuality, setVideoQuality] = useState<any>(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deviceSetting, setDeviceSetting] = useState({
    camera_mode: '',
    burst_mode: '',
    motion_sensitivity: '',
    picture_size: '',
    camera_check_int: '',
    sending_mode: '',
    night_mode: '',
    ir_flash: '',
    delay: '',
    camera_name: '',
    time_lapse: '',
    work_timer1: '',
    work_timer2: '',
    sd_card: 0,
    sd_card_max: 0,
    trans_video: '',
    video_quality: '',
    video_length: '',
    estimated_update_at: 0,
  });

  useEffect(() => {
    console.log('device', props.device);

    setCameraMode(props.device.camera_mode);
    setVideoQuality(props.device.video_quality);

    if (props.device.camera_name == '' || props.device.camera_name == 'OFF') {
      setSwitchName(false);
    } else {
      setSwitchName(true);
    }
    let lapse = props.device.time_lapse;
    if (lapse == 'OFF') {
      setSwitchTimeLapse(false);
      lapse = '00:00:00';
    } else {
      setSwitchTimeLapse(true);
    }

    let work_timer1 = [moment('00:00', format), moment('00:00', format)];
    if (props.device.work_timer1 == 'OFF') {
      setWorkTimer1(false);
    } else {
      setWorkTimer1(true);
      const work_time = props.device.work_timer1.split('-');
      work_timer1 = [moment(work_time[0], format), moment(work_time[1], format)];
    }

    let work_timer2 = [moment('00:00', format), moment('00:00', format)];
    if (props.device.work_timer2 == 'OFF') {
      setWorkTimer2(false);
    } else {
      setWorkTimer2(true);
      const work_time = props.device.work_timer2.split('-');
      work_timer2 = [moment(work_time[0], format), moment(work_time[1], format)];
    }
    form.setFieldsValue({ ...props.device, delayMoment: moment(props.device.delay ? props.device.delay : '00:00:00', 'HH:mm:ss'), timeLapseMoment: moment(lapse, 'HH:mm:ss'), workTimerMoment1: work_timer1, workTimerMoment2: work_timer2 });

    setIsLastFirmware(props.isLastFirmware);

    http_load_device_setting(props.camera.IMEI);
  }, [props.device]);

  const onGetPicture = () => {
    const params = {
      IMEI: props.device.IMEI,
    };
    Api.REMOTE_CONTROL_GETAPICKTURE_MINI(params).then((res: any) => {
      notification.open({
        message: res.text,
      });
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onReset = () => {
    const params = {
      IMEI: props.device.IMEI,
    };
    Api.REMOTE_CONTROL_RESET_MINI(params).then((res: any) => {
      notification.open({
        message: res.text,
      });
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onFirmwareUpdate = () => {
    if (deviceSetting.picture_size == '') {
      notification.open({
        duration: 10,
        message: 'Your Camera has not sent a daily report to HuntControl yet. This usually happens after 24-48hrs of continuous operation. Please wait until the camera checks in, and you can update at that time. Please contact support at support@wiseeyetech.com with any questions.',
      });
    } else if (deviceSetting.camera_check_int == '4 Times Daily (default)' || props.device.firmware_version != '5MR1HDwD404') {
      setIsModalOpen(true);
    } else {
      notification.open({
        duration: 10,
        message: 'Your camera will have to be in Daily or 4 Times Daily Check In Mode for the update to happen. Please change your check in mode using the remote control menu above. You can attempt the firmware update again, after the camera confirms this setting at its next report.',
      });
    }
  };

  const onFinish = (values) => {
    let delay = '00:00:00';
    console.log(values);
    if (values.delayMoment) { delay = values.delayMoment.format('HH:mm:ss'); }
    if (delay < '00:00:05') {
      delay = '00:00:05';
      form.setFieldsValue({ delayMoment: moment(delay, 'HH:mm:ss') });
    }

    let { camera_name } = values;
    if (!switchName) {
      camera_name = '';
    }

    let time_lapse = 'OFF';
    if (switchTimeLapse) {
      time_lapse = '00:00:00';
      if (values.timeLapseMoment) { time_lapse = values.timeLapseMoment.format('HH:mm:ss'); }
      if (time_lapse < '00:00:03') {
        time_lapse = '00:00:03';
        form.setFieldsValue({ timeLapseMoment: moment(time_lapse, 'HH:mm:ss') });
      }
    }
    let work_timer1 = 'OFF';
    if (workTimer1) {
      work_timer1 = '00:00-00:00';
      if (values.workTimerMoment1) {
        const time1 = values.workTimerMoment1[0].format(format);
        const time2 = values.workTimerMoment1[1].format(format);
        work_timer1 = `${time1}-${time2}`;
      }
    }

    let work_timer2 = 'OFF';
    if (workTimer2) {
      work_timer2 = '00:00-00:00';
      if (values.workTimerMoment2) {
        const time1 = values.workTimerMoment2[0].format(format);
        const time2 = values.workTimerMoment2[1].format(format);
        work_timer2 = `${time1}-${time2}`;
      }
    }

    if (values.camera_mode == 2) {
      form.setFieldsValue({ multi_shot: 1 });
      values.multi_shot = 1;
    }

    const params = {
      IMEI: props.device.IMEI,
      multi_shot: values.multi_shot,
      pir_sensitivity: values.pir_sensitivity < 1 ? 7 : values.pir_sensitivity,
      picture_size: values.picture_size,
      sms_remote: values.sms_remote,
      camera_name,
      delay,
      camera_mode: values.camera_mode,
      night_mode: values.night_mode,
      sending_mode: values.sending_mode,
      ir_flash: values.ir_flash,
      trans_video: values.trans_video,
      video_quality: values.video_quality,
      video_length: values.video_length,
      time_lapse,
      work_timer1,
      work_timer2,
    };
    Api.REMOTE_CONTROL_MINI_D404(params).then(((res: any) => {
      notification.open({
        message: 'Your new settings have been sent to your camera and will take effect the next time your camera connects to the server.',
      });
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const getLabel = (value, options) => {
    for (let i = 0; i < options.length; i++) {
      if (parseInt(value, 10) == options[i].value) {
        return options[i].label;
      }
    }
    return '';
  };

  const http_load_device_setting = (IMEI) => {
    Api.REMOTE_CONTROL_DEVICE_SETTING(IMEI).then(((data: any) => {
      const res = JSON.parse(data.text);
      console.log(res);
      if (res.result == 'OK') {
        const new_device = {
          camera_mode: getLabel(res.device.camera_mode, optionsCameraMode),
          burst_mode: getLabel(res.device.multi_shot, optionsMultiShot),
          motion_sensitivity: getLabel(res.device.pir_sensitivity, optionsPirSensitivity),
          picture_size: getLabel(res.device.picture_size, optionsPictureSize),
          camera_check_int: getLabel(res.device.sms_remote, optionsCellular),
          sending_mode: getLabel(res.device.sending_mode, optionsSendingMode),
          night_mode: getLabel(res.device.night_mode, optionsNightMode),
          ir_flash: getLabel(res.device.ir_flash, optionsIRFlash),
          trans_video: getLabel(res.device.trans_video, optionsVideoSending),
          video_quality: getLabel(res.device.video_quality, optionsVideoSize),
          video_length: getLabel(res.device.video_length, optionsVideoLength),
          delay: res.device.delay,
          camera_name: res.device.camera_name,
          time_lapse: res.device.time_lapse,
          work_timer1: res.device.work_timer1,
          work_timer2: res.device.work_timer2,
          sd_card: res.device.sd_card,
          sd_card_max: res.device.sd_card_max,
          estimated_update_at: res.device.estimated_update_at,
        };
        setDeviceSetting(new_device);
      }
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };
  const http_formware_update = () => {
    setIsModalOpen(false);
    const params = {
      IMEI: props.device.IMEI,
    };
    Api.REMOTE_CONTROL_FIRMWARE_UPDATE_MINI(params).then((res: any) => {
      const data = JSON.parse(res.text);
      if (data.result == 'OK') {
        const date = new Date(data.estimated_time * 1000).toLocaleString();
        const confirm_message = `Your update request has been sent to the camera and should be completed by ${date}. Please check back at that time.`;
        notification.open({
          message: confirm_message,
        });
        http_load_device_setting(props.camera.IMEI);
      } else {
        notification.open({
          message: data.message,
          duration: 10,
        });
      }
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };
  return (
    <>
      {deviceSetting.estimated_update_at * 1000 > Date.now() && (
        <Alert
          message=""
          description={`Your firmware update request has been sent to the camera and should be completed by ${new Date(deviceSetting.estimated_update_at * 1000).toLocaleString()}. Please check back at that time.`}
          type="warning"
          closable
        />
      )}
      <Form
        {...layout}
        form={form}
        name="basic"
        onFinish={onFinish}
      >
        <Card className={classes.card}>
          <Row>
            <Col md={12} xs={24}>
              <Form.Item
                label="Camera Mode"
                name="camera_mode"
                tooltip={{ title: 'Choose whether you wish to receive videos, pictures or both videos and pictures. Please be aware that receiving videos will use significantly more data than just pictures.', placement: 'bottom' }}
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsCameraMode}
                  onChange={(value) => {
                    setCameraMode(value);
                    if (value == 2) {
                      form.setFieldsValue({ multi_shot: 1 });
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Burst Mode"
                name="multi_shot"
                tooltip={{ title: 'Choose how many pictures you would like the camera to take with each motion trigger. This only works in Photo mode.', placement: 'bottom' }}
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsMultiShot}
                  disabled={cameraMode != 1}
                />
              </Form.Item>
              <Form.Item
                label="Motion Sensitivity"
                name="pir_sensitivity"
                tooltip={{ title: '9 - more sensitive to motion. 1 - least sensitive to motion.', placement: 'bottom' }}
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsPirSensitivity}
                />
              </Form.Item>
              <Form.Item
                label="Picture Size"
                name="picture_size"
                tooltip={{ title: 'This is the size of the picture that is saved on the SD Card. This will not affect the size of the images transmitted to HuntControl.', placement: 'bottom' }}
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsPictureSize}
                />
              </Form.Item>
              <Form.Item
                label="Camera Check In"
                name="sms_remote"
                tooltip={{ title: 'Once a Day - Best Battery Life, Up to 24hrs to execute remote control commands. Always Available - Instantly Execute commands, significant battery drain. Recommended only for those with solar panels or larger battery adapters.', placement: 'bottom' }}
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsCellular}
                />
              </Form.Item>
              <Form.Item
                label="Sending Mode"
                name="sending_mode"
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsSendingMode}
                />
              </Form.Item>
              <Form.Item
                label="Night Mode"
                name="night_mode"
                tooltip={{ title: 'This controls the shutter speed at night. Max Range will illuminate things further away, but it will tend to create more blur. Minimum Blur will reduce the amount of blurriness in the photo, but it will reduce the range of the flash at night. Balanced is good for most situations.', placement: 'bottom' }}
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsNightMode}
                />
              </Form.Item>
              <Form.Item
                label="IR Flash"
                name="ir_flash"
                tooltip={{ title: 'Choose how much flash you wish to use for night time pictures. Low power is better for close subjects. High power tends to wash out close subjects. Low power will reduce visible range in the pictures.', placement: 'bottom' }}
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsIRFlash}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Video Option"
                name="trans_video"
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsVideoSending}
                  disabled={cameraMode == 1}
                />
              </Form.Item>
              <Form.Item
                label="Video Quality"
                name="video_quality"
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsVideoSize}
                  onChange={(value) => { setVideoQuality(value); }}
                  disabled={cameraMode == 1}
                />
              </Form.Item>
              <Form.Item
                label="Video Length"
                name="video_length"
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={videoQuality == 1 ? optionsVideoLength1080 : optionsVideoLength}
                  disabled={cameraMode == 1}
                />
              </Form.Item>
              <Form.Item
                label="Delay"
                name="delayMoment"
                tooltip={{ title: 'This is the amount of time that the camera will wait between taking pictures.', placement: 'bottom' }}
              >
                <TimePicker
                  className={classes.control}
                  size="large"
                />
              </Form.Item>
              <Form.Item label="Camera Name" className={classes.divTimeLapse}>
                <Input.Group compact>
                  <Form.Item
                    label=""
                    name="camera_name"
                  >
                    <Input
                      disabled={!switchName}
                      maxLength={12}
                    />
                  </Form.Item>
                  &nbsp;
                  <Form.Item
                    label=""
                  >
                    <Switch checked={switchName} onChange={(value) => { setSwitchName(value); }} />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item
                label="Time Lapse:"
                className={classes.divTimeLapse}
                tooltip={{ title: 'Allows you to take pictures at an interval, regardless of the motion sensor. You can set the interval as low as 5 seconds or as high as 23 hrs and 59 minutes. Turning on Time Lapse will disable your motion sensor. If you want the camera to be triggered by motion, make sure the Time Lapse is not enabled.', placement: 'bottom' }}
              >
                <Input.Group compact>
                  <Form.Item
                    label=""
                    name="timeLapseMoment"
                  >
                    <TimePicker
                      className={classes.control}
                      size="large"
                      defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                      disabled={!switchTimeLapse}
                    />
                  </Form.Item>
                  &nbsp;
                  <Form.Item
                    label=""
                  >
                    <Switch
                      checked={switchTimeLapse}
                      onChange={(checked) => {
                        console.log(`switch to ${checked}`);
                        setSwitchTimeLapse(checked);
                        if (checked) {
                          form.setFieldsValue({ timeLapseMoment: moment('00:00:03', 'HH:mm:ss') });
                        }
                      }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item
                label="Work Timer1"
                className={classes.divTimeLapse}
                tooltip={{ title: 'Select which hours during the day that you would like the camera to be active. Do not set this if you wish the camera to always be active.', placement: 'bottom' }}
              >
                <Input.Group compact>
                  <Form.Item
                    label=""
                    name="workTimerMoment1"
                  >
                    <TimePicker.RangePicker
                      className={classes.control}
                      size="large"
                      defaultOpenValue={moment('00:00', format)}
                      disabled={!workTimer1}
                      format={format}
                    />
                  </Form.Item>
                  &nbsp;
                  <Form.Item
                    label=""
                  >
                    <Switch
                      checked={workTimer1}
                      onChange={(checked) => {
                        console.log(`switch to ${checked}`);
                        setWorkTimer1(checked);
                        if (checked) {
                          form.setFieldsValue({ workTimerMoment1: moment('00:00:03', 'HH:mm:ss') });
                        }
                      }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item
                label="Work Timer2"
                className={classes.divTimeLapse}
                tooltip={{ title: 'Select which hours during the day that you would like the camera to be active. Do not set this if you wish the camera to always be active.', placement: 'bottom' }}
              >
                <Input.Group compact>
                  <Form.Item
                    label=""
                    name="workTimerMoment2"
                  >
                    <TimePicker.RangePicker
                      className={classes.control}
                      size="large"
                      defaultOpenValue={moment('00:00', format)}
                      disabled={!workTimer2}
                      format={format}
                    />
                  </Form.Item>
                  &nbsp;
                  <Form.Item
                    label=""
                  >
                    <Switch
                      checked={workTimer2}
                      onChange={(checked) => {
                        console.log(`switch to ${checked}`);
                        setWorkTimer2(checked);
                        if (checked) {
                          form.setFieldsValue({ workTimer2Moment: moment('00:00:03', 'HH:mm:ss') });
                        }
                      }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          {(deviceSetting.estimated_update_at == null || deviceSetting.estimated_update_at * 1000 < Date.now()) && (
            <>
              <div className={classes.divFooter}>
                <Button type="primary" htmlType="submit">
                  Send
                </Button>
              </div>
              <Divider />
              <div className={classes.divBottomMobile}>
                {
                  (!isLastFirmware) && (
                    <div className={classes.divLine}>
                      <Button type="primary" onClick={onFirmwareUpdate}>
                        Camera Firmware Update
                      </Button>
                    </div>
                  )
                }
                <div className={classes.divLine}>
                  <Tooltip placement="bottom" title="The reset command can resolve many common issues with the camera. Only use if you are having trouble with your camera. This will remove all of your settings from the camera. Do you wish to proceed?">
                    <Popconfirm title="Sure to Reset?" onConfirm={onReset}>
                      <Button type="primary">
                        Reset
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                </div>
                <div className={classes.divLine}>
                  <Tooltip title="This will tell the camera to send you a picture the next time that the camera checks in. If your camera is set to Daily Cellular Mode then it may take up to 24hrs to get the image. If your camera is set to Instant Cellular Mode then it should send the picture in a few minutes at most. The picture will show in your image gallery.">
                    <Button type="primary" onClick={onGetPicture}>
                      Get A Picture
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </>
          )}
        </Card>
      </Form>
      <Collapse defaultActiveKey={[]}>
        <Collapse.Panel header="Settings Received From Camera" key="1">
          <div className={classes.divBottomHeader}>
            <div className={classes.divTitle}>
              <h1 className={classes.labelTitle}>{props.camera.name}</h1>
              <Tooltip placement="leftTop" title="Below you can see the most recent settings on your camera. These may be different from the settings above. The cameras check in with HuntControl based on your settings above.Cameras in Always Available will get your commands quickly and update quickly, but this requires much power and will drain AA batteries. Cameras in Daily will get your commands only once a day, so there may be a delay between the commands being sent and the settings being changed on the camera. If your camera is in Daily, please allow up to 24 hrs for the commands to process.The Last Check In time shows the last time the camera checked for new commands from HuntControl.">
                <QuestionCircleOutlined style={{ color: '#DC6300' }} />
              </Tooltip>
            </div>
            <div className={classes.divFirmware}>
              <Row className={classes.labelFirmware}>
                <Col md={12} xs={24}>
                  <span>Last Updated: {props.device.last_text_uploaded_at > 1000 ? moment(props.device.last_text_uploaded_at * 1000).format('hh:mm A - MMM D, Y') : ''}</span>
                </Col>
                <Col md={12} xs={24}>
                  <span>Firmware Version: {props.device.firmware_version}</span>
                </Col>
              </Row>
            </div>
          </div>
          <Divider />
          <div className={classes.divBottomContent}>
            <div className={classes.divCameraType}>
              <CameraVersion detail={props.device} size={md ? 150 : 60} />
              <AttComponent detail={props.device} />
            </div>
            {md && (
              <div className={classes.divText}>
                <table className={classes.border_none}>
                  <tr>
                    <td className={classes.td_title}>Camera Mode:</td>
                    <td className={classes.td_value}>{deviceSetting.camera_mode}</td>
                    <td className={classes.td_title}>Video Option: </td>
                    <td className={classes.td_value}>{deviceSetting.trans_video}</td>
                  </tr>
                  <tr>
                    <td className={classes.td_title}>Burst Mode:</td>
                    <td className={classes.td_value}>{deviceSetting.burst_mode}</td>
                    <td className={classes.td_title}>Video Quality: </td>
                    <td className={classes.td_value}>{deviceSetting.video_quality}</td>
                  </tr>
                  <tr>
                    <td className={classes.td_title}>Motion Sensitivity:</td>
                    <td className={classes.td_value}>{deviceSetting.motion_sensitivity}</td>
                    <td className={classes.td_title}>Video Length: </td>
                    <td className={classes.td_value}>{deviceSetting.video_length}</td>
                  </tr>
                  <tr>
                    <td className={classes.td_title}>Picture Size:</td>
                    <td className={classes.td_value}>{deviceSetting.picture_size}</td>
                    <td className={classes.td_title}>Delay: </td>
                    <td className={classes.td_value}>{deviceSetting.delay}</td>
                  </tr>
                  <tr>
                    <td className={classes.td_title}>Sending Mode: </td>
                    <td className={classes.td_value}>{deviceSetting.sending_mode}</td>
                    <td className={classes.td_title}>Camera Name: </td>
                    <td className={classes.td_value}>{deviceSetting.camera_name}</td>
                  </tr>
                  <tr>
                    <td className={classes.td_title}>Camera Check In: </td>
                    <td className={classes.td_value}>{deviceSetting.camera_check_int}</td>
                    <td className={classes.td_title}>Time Lapse: </td>
                    <td className={classes.td_value}>{deviceSetting.time_lapse}</td>
                  </tr>
                  <tr>
                    <td className={classes.td_title}>Night Mode: </td>
                    <td className={classes.td_value}>{deviceSetting.night_mode}</td>
                    <td className={classes.td_title}>Work Timer1: </td>
                    <td className={classes.td_value}>{deviceSetting.work_timer1}</td>
                  </tr>
                  <tr>
                    <td className={classes.td_title}>IR Flash: </td>
                    <td className={classes.td_value}>{deviceSetting.ir_flash}</td>
                    <td className={classes.td_title}>Work Timer2: </td>
                    <td className={classes.td_value}>{deviceSetting.work_timer2}</td>
                  </tr>
                </table>
              </div>
            )}
            <div className={classes.divImages}>
              <div className={classes.divCard}>
                <img className={classes.image} alt="test" src="/icons_camera/sdcard_icon.png" />
                <p>{(props.device.sd_card_max - props.device.sd_card)}GB/{props.device.sd_card_max}GB</p>
                <p>{Math.round(((props.device.sd_card_max - props.device.sd_card) * 100) / (props.device.sd_card_max + 0.01))}%</p>
              </div>
              <BatteryComponent detail={props.device} fontSize="large" />
              <div className={classes.iconSignal}>
                <SignalComponent detail={props.device} fontSize="large" />
              </div>
            </div>
          </div>
          {!md && (
            <div className={classes.divText}>
              <table className={classes.border_none}>
                <tr>
                  <td className={classes.td_title}>Camera Mode:</td>
                  <td className={classes.td_value}>{deviceSetting.camera_mode}</td>
                  <td className={classes.td_title}>Video Option: </td>
                  <td className={classes.td_value}>{deviceSetting.trans_video}</td>
                </tr>
                <tr>
                  <td className={classes.td_title}>Burst Mode:</td>
                  <td className={classes.td_value}>{deviceSetting.burst_mode}</td>
                  <td className={classes.td_title}>Video Quality: </td>
                  <td className={classes.td_value}>{deviceSetting.video_quality}</td>
                </tr>
                <tr>
                  <td className={classes.td_title}>Motion Sensitivity:</td>
                  <td className={classes.td_value}>{deviceSetting.motion_sensitivity}</td>
                  <td className={classes.td_title}>Video Length: </td>
                  <td className={classes.td_value}>{deviceSetting.video_length}</td>
                </tr>
                <tr>
                  <td className={classes.td_title}>Picture Size:</td>
                  <td className={classes.td_value}>{deviceSetting.picture_size}</td>
                  <td className={classes.td_title}>Delay: </td>
                  <td className={classes.td_value}>{deviceSetting.delay}</td>
                </tr>
                <tr>
                  <td className={classes.td_title}>Sending Mode: </td>
                  <td className={classes.td_value}>{deviceSetting.sending_mode}</td>
                  <td className={classes.td_title}>Camera Name: </td>
                  <td className={classes.td_value}>{deviceSetting.camera_name}</td>
                </tr>
                <tr>
                  <td className={classes.td_title}>Camera Check In: </td>
                  <td className={classes.td_value}>{deviceSetting.camera_check_int}</td>
                  <td className={classes.td_title}>Time Lapse: </td>
                  <td className={classes.td_value}>{deviceSetting.time_lapse}</td>
                </tr>
                <tr>
                  <td className={classes.td_title}>Night Mode: </td>
                  <td className={classes.td_value}>{deviceSetting.night_mode}</td>
                  <td className={classes.td_title}>Work Timer1: </td>
                  <td className={classes.td_value}>{deviceSetting.work_timer1}</td>
                </tr>
                <tr>
                  <td className={classes.td_title}>IR Flash: </td>
                  <td className={classes.td_value}>{deviceSetting.ir_flash}</td>
                  <td className={classes.td_title}>Work Timer2: </td>
                  <td className={classes.td_value}>{deviceSetting.work_timer2}</td>
                </tr>
              </table>
            </div>
          )}
        </Collapse.Panel>
      </Collapse>
      <Modal title="" visible={isModalOpen} onOk={http_formware_update} onCancel={() => { setIsModalOpen(false); }}>
        <p>The update may take up to 48hrs to complete. During this time you will be unable to send additional remote control commands. The camera will have to have sufficient battery power and signal for the update to complete properly. After the update, your camera will revert to the default values for all settings. Your camera will continue to send images during the update process. None of your images or data will be affected during the update. Do you wish to proceed?</p>
      </Modal>
    </>
  );
};

export default CameraRemoteMiniV2;
