import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Grid, Spin } from 'antd';
import CameraCard from 'components/cameraCard';
import LeftCameraMap from 'components/cameraMap';

import * as Api from 'api';
import useAuth from 'redux/auth/auth.hook';
import { CameraPinDetailType, CameraSubType } from 'helpers/types';
import SubCameraCard from 'components/SubCameraCard';

import classes from './style.module.scss';

const { useBreakpoint } = Grid;

const SubCameraPage = () => {
  const { md } = useBreakpoint();

  const { auth: { profile } } = useAuth();
  const [cameras, setCameras] = useState<CameraSubType[]>([]);
  const [loading, setLoading] = useState(false);
  const leftMap = useRef<any>();

  useEffect(() => {
    if (profile) {
      loadData();
    }
  }, [profile]);

  const loadData = () => {
    setLoading(true);
    Api.SUB_CAMERA_ALL().then(((res: any) => {
      const result = JSON.parse(res.text);
      setCameras(result.cameras);
      setLoading(false);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  return (
    <>
      <Row>
        <Col md={12} xs={24} className={classes.leftMap}>
          <LeftCameraMap cameras={cameras} ref={leftMap} />
        </Col>
        <Col md={12} xs={24}>
          <div className={classes.content} style={{ padding: md ? '12px 12px 12px 0px' : 8 }}>
            <Col xs={24} md={24}>
              <Spin spinning={loading} size="large">
                <Row>
                  {cameras.map((e) => (
                    <Col key={e.id} xs={24} md={24}>
                      <SubCameraCard detail={e} />
                    </Col>
                  ))}
                </Row>
              </Spin>
            </Col>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SubCameraPage;
