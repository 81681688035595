import { GoogleMap, InfoWindow, LoadScript, Marker, MarkerClusterer } from '@react-google-maps/api';
import { message, Modal, Popconfirm } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import useApi from 'redux/api/api.hook';
import { useHistory } from 'react-router-dom';
import * as Api from 'api';

const containerStyle = {
  height: '100%',
};

const LeftCameraMap = ({ cameras }, ref) => {
  const history = useHistory();
  const [map, setMap] = useState<any>();
  const { cameraMapBound, setCameraMapBound } = useApi();

  const showModal = (e) => {
    console.log(e);
    // httpDelete(arg.id);
    history.push(e.type == '' || e.type == null ? `/cameraEdit/${e.id}` : `/cameraEditPin/${e.id}`);
  };

  useEffect(() => {
    fitBounds(map);
  }, [cameras]);

  const fitBounds = (mapInstance) => {
    if (mapInstance) {
      const bounds = new window.google.maps.LatLngBounds();
      if (cameras.length > 0) {
        cameras.forEach((child) => {
          bounds.extend({ lat: Number(child.latitude), lng: Number(child.longitude) });
        });
        mapInstance.fitBounds(bounds);
      }
    }
  };

  const loadHandler = (mapInstance) => {
    setMap(mapInstance);
    fitBounds(mapInstance);
  };

  const onRightClick = (e) => {
    console.log(e.lat, e.lng);
  };

  useImperativeHandle(ref, () => ({
    handleMapBoundChange() {
      const mapCenter = map.getCenter();
      setCameraMapBound({
        center: {
          lat: mapCenter.lat(),
          lng: mapCenter.lng(),
        },
        zoom: map.getZoom(),
      });
    },
  }));

  const options = {
    imagePath:
      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
  };

  const httpDelete = (camera_id: string) => {
    Api.CAMERA_DELETE(camera_id).then((res) => {
      console.log('delete camera', res);
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyAnFBws_Bc64W-xl_4HULAoH0oG664xLt4"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        onLoad={loadHandler}
        mapTypeId="hybrid"
        center={cameraMapBound.center}
        zoom={cameraMapBound.zoom}
        onRightClick={(e) => onRightClick(e!.latLng!.toJSON())}
      >
        <MarkerClusterer options={options}>
          {(clusterer) =>
            cameras.map((e, i) => (
              // <Link to={e.type == '' || e.type == null ? `/cameraEdit/${e.id}` : `/cameraEditPin/${e.id}`}>
              <Marker
                key={i}
                title={e.name}
                clusterer={clusterer}
                onClick={() => showModal(e)}
                icon={{ url: `/map_pins/${e.type ? e.type : 'Camera'}.png` }}
                position={{ lat: Number(e.latitude), lng: Number(e.longitude) }}
              />
              // </Link>
            ))}
        </MarkerClusterer>
      </GoogleMap>
    </LoadScript>
  );
};

export default forwardRef(LeftCameraMap);
